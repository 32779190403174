.calendar-upcoming-event-page,
.upcoming-event-details-page {
	height: 100vh;
	overflow-y: hidden;
	display: flex;
}

.calendar-upcoming-event,
.upcoming-event-details {
	width: 100%;
	overflow-y: auto;
}

.calendar-upcoming-event .upcoming-event {
	background: none;
	padding-left: 0;
	padding-right: 0;
	cursor: pointer;
}

.calendar-upcoming-event-main,
.upcoming-event-details-main {
	padding: 0 1.5rem 2rem;
	margin-bottom: 4rem;
}

.upcoming-event-details-main {
	max-width: 64.8125rem;
}

.calendar-upcoming-events {
	max-width: 20.75rem;
	margin: 1.375rem auto 0;
}

.upcoming-events-heading {
	font-size: 1rem;
	font-weight: 600;
	margin-bottom: 1.125rem;
}

.planner-main-calendar div {
	margin: 0 auto;
}

.upcoming-event-details-main h1 {
	font-size: 1.25rem;
	font-weight: 400;
}

.upcoming-event-vendors-container {
	margin-top: 3.25rem;
}

.vendor-box {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 2.6875rem;
}

.vendor-profile-box {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	flex: 1;
	cursor: pointer;
	max-width: 17rem;
}

.vendor-profile-box p {
	max-width: 12.0625rem;
}

.vendor-profile-box img {
	margin-left: auto;
}

.vendor-image-box img {
	height: 2rem;
	width: 2rem;
	border-radius: 50%;
}

.vendor-profile-arrow {
	width: 0.375rem;
	height: 0.75rem;
}

.vendor-type {
	flex: 1;
}

@media screen and (min-width: 37.5em) {
	.vendor-profile-arrow {
		width: auto;
		height: auto;
	}
}

@media screen and (min-width: 47.9375em) {
	.calendar-upcoming-event-main,
	.upcoming-event-details-main {
		padding: 0 1.675rem 2rem;
		margin-bottom: 4rem;
	}

	.calendar-upcoming-event-page {
		background-color: #f1f5fa;
	}

	.upcoming-events-container {
		background-color: #f7fcf5;
		height: 24rem;
		padding: 0 1rem;
		border-radius: 16px;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.calendar-upcoming-event-page .upcoming-event {
		padding: 2.8125rem 0;
		border-bottom: 1px;
	}

	.calendar-upcoming-events {
		max-width: 100%;
		margin: 1.375rem 0 0 0;
	}

	.planner-main-calendar div {
		margin: 0;
		width: 100%;
	}

	.calendar-upcoming-event-main {
		display: flex;
		align-items: center;
		gap: 2.25rem;
		max-width: 65.75rem;
		margin: 0 auto;
	}

	.planner-main-calendar {
		flex: 40%;
		margin-top: 1.8rem;
	}

	.calendar-upcoming-events {
		flex: 60%;
	}

	.upcoming-events-heading {
		font-size: 1.25rem;
	}

	.calendar-upcoming-event-page .upcoming-event-name {
		font-size: 1.25rem !important;
	}

	.calendar-upcoming-event-page .upcoming-event-location {
		font-size: 1rem !important;
	}

	.calendar-upcoming-event-page .upcoming-event:nth-of-type(1) {
		border-bottom: 1px solid rgba(132, 216, 43, 0.3) !important;
	}

	.upcoming-event-details-main {
		margin-top: 2rem;
	}

	.upcoming-event-details-main h1 {
		font-size: 1.5rem;
	}

	.vendor-type,
	.vendor-profile-box p {
		font-size: 1.25rem;
	}

	.vendor-image-box img {
		height: 2.5rem;
		width: 2.5rem;
	}
}
