.cancel-and-decline-modal {
	text-align: left;
	padding: 2.875rem 1.375rem;
	max-width: 40rem;
}

.cancel-and-decline-heading {
	font-size: 1.25rem;
	text-align: left;
}

.cancel-and-decline-form {
	margin-block-start: 1rem;
}

.cancel-and-decline-form .reason {
	display: flex;
	align-items: center;
	gap: 1.3125rem;
	width: 100%;
	cursor: pointer;
	padding: 0.75rem;
	border-radius: 6px;
}

.cancel-and-decline-form .reason,
.cancel-and-decline-form .other-reason {
	margin-top: 0.5rem;
}

.cancel-and-decline-form .other-reason label {
	display: block;
	margin-bottom: 0.75rem;
	text-align: left;
	padding-left: 0.75rem;
}

.cancel-and-decline-form .other-reason input {
	width: 100%;
	border-radius: 4px;
	background: var(--blue-light, #f1f5fa);
	border: none;
	padding: 1rem 0.75rem;
}

.cancel-and-decline-form .other-reason input:focus {
	outline-color: #1c5d99;
}

/* .decline-offer-btn {
	margin-bottom: 3.375rem;
} */

@media screen and (min-width: 47.9375em) {
	.cancel-and-decline-modal {
		padding: 3.5rem 9.3125rem;
	}

	.decline-offer-btn, .cancel-request-btn {
		width: 100%;
    	padding-block: 10px;
    	margin-block-start: 2rem;
    	border-radius: 8px;
		background-color: #1C5D99;
		color: #fff;
		cursor: pointer;
	}
	.decline-offer-btn:disabled, .cancel-request-btn:disabled{
		background-color: #66a0d7;
		cursor: not-allowed;
	}
}
