
.planner-events-page,
.planner-event-details-page {
  height: 100vh;
  overflow: hidden;
  display: flex;
}

.planner-events-main,
.planner-event-details {
  /* width: 100%; */
  flex: 1;
}

.planner-events-main{
  display: flex;
  flex-direction: column;
}

.events-container,
.planner-vendors {
  position: relative;
  height: 40rem;
  overflow-y: auto;
  scrollbar-width: thin;
  -ms-overflow-style: none;
}

.event-name + p{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  font-size: 1.5rem;
  font-weight: 600;
  color: #1c5d99;
}

.events-container::-webkit-scrollbar,
.planner-vendors::-webkit-scrollbar,
.event-vendors-offers::-webkit-scrollbar {
  width: 0.3em;
}

.events-container::-webkit-scrollbar-thumb,
.planner-vendors::-webkit-scrollbar-thumb,
.event-vendors-offers::-webkit-scrollbar-thumb {
  background-color: #1c5d99;
  border-radius: 8px;
}

.events-container::-webkit-scrollbar-track,
.planner-vendors::-webkit-scrollbar-track,
.event-vendors-offers::-webkit-scrollbar-track {
  background-color: #eee;
}

.planner-event {
  margin-bottom: 1rem;
  padding: 1rem 1.25rem;
  /* padding-block:0.5rem; */
  background-color: #e1f8fd78;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border-left: 6px #1c5d99 solid;
}

.planner-events,
.planner-vendors {
  padding: 1.5rem 1.5rem 0;
}

.planner-events{
  flex: 1;
}

.planner-event,
.planner-vendor {
  cursor: pointer;
}

.planner-event:nth-of-type(2n) {
  background-color: #f0fcf7;
  border-left: 6px #024227 solid;
}

.planner-event:nth-of-type(3n) {
  background-color: rgba(255, 244.37, 252.03, 0.73);
  border-left: 6px #64054b solid;
}

.planner-event:nth-of-type(2n) .more-info-btn {
  background-color: #024227;
}

.planner-event:nth-of-type(3n) .more-info-btn {
  background-color: #64054b;
}

/* .event-info h2 {
  padding-bottom: 0.75rem;
} */
.event-info h2,
.planner-event-details h2 {
  font-size: 1.4rem;
  font-weight: 600;
}

.event-info p {
  margin-top: 0.5rem;
  font-size: 0.75rem;
}

.more-info-btn {
  background-color: #4278aa;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 99px;
  position: relative;
}

.more-info-btn svg,
.add-event-btn svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.add-event-btn,
.add-service-btn,
.more-info-btn,
.services-info-btn {
  cursor: pointer;
}

.add-event-btn {
  background-color: #1c5d99;
  height: 3.625rem;
  width: 3.625rem;
  border-radius: 99px;
  position: fixed;
  right: 5%;
  bottom: 5%;
}

@media screen and (max-width: 426px) {
  .add-event-btn{
    height: 2.5rem;
    width: 2.5rem;
    bottom: 15%;
  }
  .add-event-btn svg{
    width: 1.5rem
  }
}

.add-service-btn{
  position: fixed;
  right: 3rem;
  bottom: 12rem;
  background-color: #1c5d99;
  color: white;
  display: flex;
  gap: 4px;
  padding: 10px;
  justify-content: center;
  border-radius: 4px;
  right: 5%;
  bottom: 5%;
}

.add-service-btn svg{
  width:16px;
  height: 16px;
}

.add-service-btn p{
  font-size: 16px;
}

@media screen and (max-width: 426px) {
  .add-service-btn{
    bottom: 15%;
  }
  /* .add-event-btn svg{
    width: 1.5rem
  } */
}

.no-planner-event {
  width: 100%;
  padding: 0 3.625rem;
  text-align: center;
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.no-planner-event h2 {
  font-weight: 700;
  color: #1c5d99;
  padding-bottom: 0.875rem;
}

.no-planner-event h2,
.planner-event-details h1 {
  font-size: 1.2rem;
}

.no-planner-event p {
  color: #5f5f5f;
}

.planner-event-details h1 {
  font-weight: 400;
  text-align: center;
}

.event-details-dropdown {
  background-color: #f1f5fa;
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;
  /* width: 11.6875rem; */
  position: absolute;
  right: 0;
  /* bottom: -3rem; */
  display: flex;
  align-items: center;
  gap: .5rem;
  font-size: 1rem;
  display: none;
  cursor: pointer;
}

.event-details-dropdown:active, .event-details-dropdown:hover{
  background-color: #DDE3E9;
}

@media screen and (max-width: 425px) {
  .event-details-dropdown{
    padding: 0.75rem;
  }
}

.planner-vendors .event-name {
  position: relative;
  margin-bottom: 4rem;
}

.planner-vendors .event-name .ellipses-box {
  position: absolute;
  top: .3rem;
  right: 0;
  cursor: pointer;
}

.planner-vendors .event-details-dropdown {
  display: flex;
}

.planner-vendor {
  padding: 1.2rem 1rem 1.2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.125rem;
  background-color: #f0fcf7;
  border-radius: 6px;
}

.planner-vendor:nth-of-type(2n) {
  background-color: rgba(255, 244.37, 252.03, 0.73);
}

.planner-vendor:nth-of-type(3n) {
  background-color: #e1f8fd78;
}

@media screen and (min-width: 48em) {
  .planner-event-details {
    height: 100vh;
  }

  .planner-events,
  .planner-vendors {
    padding: 1.5rem 2rem 0;
  }

  .add-event-btn {
    height: 4.5rem;
    width: 4.5rem;
    /* bottom: 10re */
  }

  /* .add-service-btn{

  } */
  .add-event-btn svg {
    width: 3.125rem;
    height: 3.125rem;
  }
  /* .add-service-btn svg{

  } */

  .planner-vendors .event-name .ellipses-box {
    top: -1rem;
  }

  .no-planner-event h2,
  .planner-event-details h1 {
    font-size: 1.5rem;
  }

  .events-container,
  .planner-vendors {
    height: 50rem;
  }
}

@media screen and (min-width: 62.5em) {
  .events-container,
  .planner-vendors {
    height: 30rem;
  }

  /* .add-event-btn,
  .add-service-btn {
    bottom: 5rem;
  } */
}

@media screen and (min-width: 75em) {
  /* .add-event-btn,
  .add-service-btn {
    bottom: 3rem;
  } */
}

.events-container{
  height: 100%;
  position: relative;
}
