.order-summary {
  background-color: #f1f5fa;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 2rem 1.5rem;
}

.order-summary-left-arrow {
  display: flex;
  align-items: center;
  gap: .5rem;
  margin-bottom: 2rem;
}

.order-summary-heading-container {
  display: flex;
  justify-content: end;
  gap: .5rem;
  flex-wrap: wrap;
}

.order-summary > p{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.order-summary-heading {
  display: none;
}

.order-summary-edit-btn {
  text-decoration: underline;
  color: #1c5d99;
  cursor: pointer;
  margin-bottom: 1.0625rem;
  display: flex;
  align-items: end;
  font-weight: 600;
}

.order-summary-detail {
  margin-bottom: 0.5rem;
}
.order-summary-detail.image-container{
  width: 250px;
  aspect-ratio: 2/1;
}

.order-summary-detail.image-container img{
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 10px;
}
.order-summary-detail .order-title {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.order-summary-detail .order-value {
  color: #5f5f5f;
}

@media screen and (min-width: 47.9375em) {
  .order-summary {
    position: relative;
    padding: 0;
  }

  .order-summary-heading-container {
    justify-content: space-between;
  }

  .order-summary-heading {
    display: block;
  }

  .order-summary-left-arrow {
    display: none;
  }

  .order-summary-edit-btn {
    margin-bottom: 0;
    font-size: 1.2rem;
    position: relative;
    right: 1.2rem;
  }

  .order-summary-details {
    background-color: white;
    padding: 1.875rem;
    margin-top: 1rem;
    border-radius: 12px;
    height: 32.1875rem;
  }
}
