.accept-offer-title {
    font-size: 1.25rem;
    color: #1C5D99;
    font-weight: 400;
}

.accept-offer-buttons {
    margin-top: 2rem;
    display: flex;
    gap: 1.25rem;
    justify-content: space-between;
}

.accept-offer-buttons button {
    border-radius: 8px;
    font-size: 1rem;
    padding: .625rem;
    width: 100%;
    cursor: pointer;
}

.accept-offer-buttons button:first-child {
    border: 1px solid #1C5D99;
    color: #1C5D99;
    background-color: white;
}

.accept-offer-buttons button:last-child {
    background-color: #1C5D99;
    color: white;
}