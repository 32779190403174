.event-vendors-page {
	height: 100vh;
	overflow: hidden;
	display: flex;
	background-color: #f1f5fa;
}

.event-vendors-main {
	flex: 1;
	overflow-y: auto;
}

.event-vendors-container {
	padding: 0 1.5rem 4rem;
	position: relative;
	overflow-y: auto;
	min-height: 10rem;
	height: auto;
}



.event-vendors-ellipses {
	border-radius: 99px;
	cursor: pointer;
	position: absolute;
	right: 1.5rem;
}
.desktop-ellipses {
	display: none;
}

.vendors-heading {
	margin-bottom: 1rem;
	height: 1.6563rem;
}

.event-vendors-dropdown-container:hover .event-vendors-dropdown {
	display: block;
}

.event-vendors-dropdown {
	background-color: #ffffff;
	/* height: 5.8125rem; */
	padding: 0.75rem 0.5rem;
	padding-right: 1.75rem;
	border-radius: 8px;
	position: absolute;
	z-index: 3;
	right: 0;
	top: 100%;
	white-space: nowrap;

}

.event-vendors-dropdown div {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	cursor: pointer;
}


.event-vendors-dropdown div:last-child {
	padding-top: 0.5rem;
}

.event-vendors-dropdown div:first-child {
	padding-bottom: 0.5rem;
	border-bottom: 1px solid #5f5f5f;
}

.event-vendors-dropdown div > img{
	width: 16px
}

.event-vendors-dropdown div > p{
	font-size: 0.875rem;
}

.no-planner-event h2 {
	font-weight: 700;
	color: #1c5d99;
	padding-bottom: 0.875rem;
	font-size: 1.25rem;
}

.no-planner-event p {
	color: #5f5f5f;
}

.event-vendors-offers {
	height: 32.1875rem;
	overflow-y: auto;
}

.event-vendor {
	background: linear-gradient(104deg, #eef9ed 0%, white 100%);
	margin-bottom: 1rem;
	padding: 1rem;
	border-radius: 6px;
}

.event-vendor-top,
.event-vendor-buttons {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	padding-bottom: 0.75rem;
}

.event-vendor-middle {
	display: flex;
	align-items: center;
	gap: 0.8rem;
	padding-bottom: 0.75rem;
}

.event-vendor-price {
	color: #1c5d99;
	font-size: 1.1rem;
	margin-left: auto;
}

.event-vendor-pic {
	cursor: pointer;
	height: 1.875rem;
	width: 1.875rem;
	border-radius: 50%;
}

.event-vendor-middle div {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.event-vendor-middle p {
	font-weight: 600;
	display: flex;
	gap: 0.2rem;
	align-items: center;
}

.event-vendor-middle span {
	font-weight: 400;
}

.instagram-handle p {
	text-decoration: underline;
}

.event-vendor-buttons {
	margin-top: 0.8125rem;
	gap: 2rem;
}

.event-vendor-buttons button {
	padding: 0.625rem 1.3rem;
	cursor: pointer;
	border-radius: 6px;
	flex-basis: 50%;
}

.event-vendor-buttons .accept-offer {
	background-color: #1c5d99;
	color: white;
}

.event-vendor-buttons .decline-offer {
	background-color: white;
	border: 1.5px solid #1c5d99;
	color: #1c5d99;
	white-space: nowrap;
}

.desktop-order-summary {
	display: none;
}

@media screen and (min-width: 21.875em) {
	.event-vendor-price {
		font-size: 1.25rem;
	}

	.event-vendor-top,
	.event-vendor-buttons {
		gap: 1rem;
	}

	.event-vendor-middle {
		gap: 1.8125rem;
	}
}

@media screen and (min-width: 48em) {
	.event-vendors-container {
		display: flex;
		flex-direction: row;
		gap: 2.5rem;
		padding: 3rem 2rem 2rem;
		min-height: 35rem;
	}

	/* .event-vendors-container > div:nth-of-type(1){
		display: flex;
		justify-content: center;
		align-items: center;
	} */

	.event-vendors-container > div.chosenVendor{
		border: 1px solid #6EB424;
		border-radius: 1rem;
	}

	.event-vendors-container .left,
	.vendor-details-left {
		flex: 1;
	}
	.event-vendors-container .desktop-order-summary{
		flex: 3
	}
	div.vendor-container{
		flex:2;
		position: relative;
	  }

	.event-vendors-ellipses {
		top: 0;
		right:0;
	}
	.event-vendors-ellipses > img{
		width: 30px
	}


	.event-vendors-dropdown {
		height: fit-content;
		right: 1.5rem;
	}

	.desktop-ellipses {
		display: block;
	}

	.mobile-ellipses {
		display: none;
	}

	.event-vendor-buttons {
		gap: 3.4375rem;
	}

	.desktop-order-summary {
		display: block;
	}

	.event-vendors-dropdown div:first-child {
		border-bottom: none;
		display: none;
	}

	.event-vendors-dropdown div:last-child {
		padding-top: 0;
	}

	.vendor-details-left-box {
		border-radius: 8px;
		border: 1px solid #6eb424;
		height: 27rem;
		padding: 2.6875rem 1.4375rem;
	}

	.vendor-details-left .vendor-details-heading-box {
		display: block;
	}

	.vendor-details-left .vendor-details-heading-box-top,
	.vendor-details-left .vendor-details-rating {
		margin-bottom: 2.875rem;
	}

	.vendor-details-left .vendor-detail {
		margin-bottom: 2rem;
	}
}
@media screen and (min-width: 75em) {
	.event-vendor-pic {
		height: 2.5rem;
		width: 2.5rem;
	}
}
