.upcoming-event-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.25rem;
}

.upcoming-event-title h2 {
  font-weight: 600;
  color: #262626;
}

.upcoming-event-info-container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  position: relative;
}

.upcoming-event-info-container img {
  position: absolute;
  top: 0;
  right: 0;
}

.calendar-title {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.calendar-title div {
  cursor: pointer;
}

.calendar-title p {
  color: #5f5f5f;
}

.no-upcoming-event {
  margin-top: 8.9375rem;
  color: #133e66;
  align-items: center;
  justify-content: center;
  text-align: center;
  row-gap: 1rem;
}

.no-upcoming-event h2 {
  color: #133e66;
  font-size: 1.25rem;
  font-weight: 600;
}

.no-upcoming-event p {
  font-weight: 400;
}

.upcoming-event {
  background: linear-gradient(171deg, #eaf7e2 0%, white 100%);
  padding: 1.1875rem 1.75rem;
  border-radius: 8px;
}

.upcoming-event-date,
.upcoming-event-location {
  font-size: 0.75rem;
}

.upcoming-event-date {
  margin-bottom: 1rem;
}

.upcoming-event-time {
  /* remove !important */
  font-size: 1rem !important;
}

.vertical-line {
  /* remove the height  */
  height: auto !important;
  width: 1px;
  background: #133e66;
}

.upcoming-event-info {
  margin-right: 1.25rem;
}

.upcoming-event-name {
  color: #133e66;
}

/* .react-calendar {
  width: 20.75rem;
} */

@media screen and (min-width: 48em) {
  .calendar-component {
    background: linear-gradient(171deg, #eaf7e2 0%, white 100%);
    padding: 1.5rem;
    border-radius: 10px;
    grid-area: calendar-component;
  }

  .upcoming-event {
    padding: 1.2rem 0;
  }

  .upcoming-event-title {
    position: relative;
  }

  .plannerpage .upcoming-event-title h2 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0;
  }

  .calendar-title div {
    width: 2.0625rem;
    height: 2.0625rem;
    background-color: #ffffff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .calendar-title p {
    left: 0;
    position: absolute;
    bottom: -2rem;
  }

  .upcoming-event {
    /* remove !important  */
    background: none;
    border-radius: 0;
  }

  .upcoming-event:nth-of-type(1) {
    border-bottom: 1px #b3c9dd solid !important;
  }

  .planner-calendar {
    margin-top: 8.9375rem !important;
  }

  .upcoming-event-date {
    /* remove !important  */
    font-size: 1rem !important;
  }

  .three-dots {
    display: none;
  }

  .upcoming-event-title h2 {
    font-size: 1.5rem;
  }
}

.react-calendar__tile.highlight::before {
  content: "";
  background-color: #1c5d99;
  position: absolute;
  transform: translateX(-130%);
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.react-calendar__tile.tooltip {
  visibility: hidden;
  background-color: #1c5d99;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  position: absolute;
  white-space: nowrap;
  height: fit-content;
  max-width: 80px; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-calendar__tile.highlight:hover .react-calendar__tile.tooltip {
  visibility: visible;
}
