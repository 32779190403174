.notifications-page {
  height: 100vh;
  overflow: hidden;
  display: flex;
}

.notifications-main {
  overflow-y: auto;
  width: 100%;
}

/* .notification-offer-container {
  padding-top: 1.4375rem;
} */

.notifications-container {
  padding: 0 4rem;
  /* margin-top: 1rem; */
  overflow-y: auto;
  /* height: 30rem; */
  /* max-width: 55.8125rem; */
  /* margin: 2rem auto 0; */
}

.notifications-container.no-noti{
  min-height: 50vh;
}

@media screen and (max-width: 769px) {
  .notifications-container {
    padding: 0 3rem;
  } 
}

@media screen and (max-width: 426px) {
  .notifications-container {
    padding: 0 2rem;
  } 
}

.notifications-container::-webkit-scrollbar {
  width: 0.3em;
}

.notifications-container::-webkit-scrollbar-thumb {
  background-color: #1c5d99;
  border-radius: 8px;
}

.notifications-container::-webkit-scrollbar-track {
  background-color: #eee;
}

.notification {
  padding-bottom: 1.4375rem;
}

.notification-message {
  font-size: 1rem;
  margin-left: 1.625rem;
}

.notification .period {
  font-size: 1rem;
}

.notification-message,
.notification .period {
  color: #5f5f5f;
}

.notification-offer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notification-offer p:first-child {
  font-size: 1.25rem;
  display: flex;
  margin-bottom: .5rem;
}

.notifications-container div:first-child .notification-offer span {
  background-color: #6eb424;
}

.notifications-container
  div:first-child
  .notification-offer
  .notification-time {
  color: #6eb424;
}

.notification-offer span {
  display: inline-block;
  border-radius: 99px;
  width: 0.625rem;
  height: 0.625rem;
  margin-right: 1rem;
  background-color: #6eb424;
}

.notification-time {
  font-size: 1rem;
  color: #6eb424;
}

@media screen and (min-width: 47.9375em) {
  .notifications-page {
    background-color: #f1f5fa;
  }
}