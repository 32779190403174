.vendor-request-page {
	height: 100vh;
	overflow-y: hidden;
	display: flex;
}

.vendor-request {
	/* width: 100%; */
	flex: 1;
	overflow-y: auto;
}

.vendor-request-main {
	padding: 0 1.5rem 2rem;
	margin-bottom: 4rem;
}

.vendor-request h1 {
	font-weight: 700;
	font-size: 1.25rem;
	margin-bottom: 0;
}

.vendor-request p {
	text-align: center;
	font-size: 1.25rem;
	font-weight: 600;
}

.vendor-request-form {
	padding-top: 1.75rem;
	display: flex;
	flex-direction: column;
	max-width: 26.625rem;
	margin: 0 auto;
}

.vendor-request input,
.vendor-request textarea,
.vendor-request select,
.vendor-request .image-input {
	padding: 1rem 1rem 0.75rem 1rem;
	border-radius: 4px;
	background-color: #f1f5fa;
	border: none;
	cursor: pointer;
	width: 100%;
}

.vendor-request input.input-error,
.vendor-request textarea.input-error,
.vendor-request select.input-error {
	border: 1px solid #ff0000;
}

.form-input {
	margin-bottom: 1rem;
}

.form-input .input-footer-text {
	font-size: 0.875rem;
	text-align: left;
}

.css-13cymwt-control {
	border-radius: 4px;
	background-color: #f1f5fa !important;
	border: none !important;
	cursor: pointer;
	margin-bottom: 1rem;
	padding: 1rem 1rem 0.75rem 1rem;
}

.css-qbdosj-Input,
.css-1jqq78o-placeholder {
	padding: 0 !important;
	margin: 0 !important;
}

.css-1fdsijx-ValueContainer {
	padding: 0 !important;
}

.vendor-request textarea {
	height: 11.6875rem;
	resize: none;
}

form input:focus,
.form-input textarea:focus {
	outline-color: #1c5d99 !important;
}

form input,
form textarea,
form select {
	color: #5f5f5f !important;
	font-size: 1rem;
}

.vendor-request select {
	appearance: none;
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23000000" width="40px" height="24px"><path d="M0 0h24v24H0z" fill="none"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/></svg>');
	background-repeat: no-repeat;
	background-position: right center;
	padding-right: 1.5625rem;
}

.css-z3c6am-MuiFormControl-root-MuiTextField-root {
	width: 100%;
	background-color: #f1f5fa;
	border: none;
}

.vendor-request ::placeholder,
.vendor-request .image-input span,
.vendor-request select {
	color: #5f5f5f;
	font-size: 1rem;
}

.vendor-request .image-input {
	display: flex;
	align-items: center;
	gap: 0.5rem;
}

.image-input-container {
	position: relative;
	border-radius: 4px;
}

.image-input-container .edit-btn {
	margin-left: auto;
}

.image-options-dropdown {
	height: 5.9375rem;
	width: 14.6875rem;
	border-radius: 0.5rem;
	position: absolute;
	right: 1rem;
	top: -2rem;
	background-color: white;
	padding: 1rem;
}

.image-options-dropdown div:first-child {
	margin-bottom: 1rem;
}

.image-options-dropdown div {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
}

.make-a-request-btn {
	font-size: 1.25rem;
	background-color: #1c5d99;
	display: flex;
	justify-content: center;
	padding: 0.6875rem 0;
	border-radius: 10px;
	color: #ffff;
	margin-top: 0.625rem;
	cursor: pointer;
}

@media screen and (min-width: 47.9375em) {
	.vendor-request-main {
		padding: 0 2rem 2rem;
	}
}
