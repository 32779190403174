.planner-modal {
    background-color: #ffffff;
    border-radius: 1.125rem;
    width: 80%;
    margin: 0 auto;
    padding: 32px;
    z-index: 3;
    top: 35%;
  }
  
  .event-success-msg {
    font-size: 1.1rem;
    font-weight: 600;
    color: #1c5d99;
    margin-bottom: 4px;
    text-align: center;
  }
  
  .event-success-image-container {
    margin-bottom: 1.6875rem;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  
  .success-modal p {
    color: #5f5f5f;
    font-size: .875rem;
  }
  
  
  .close-modal {
    position: absolute;
    top: 1.0625rem;
    right: 0.9375rem;
    cursor: pointer;
  }
  
  .planner-modal-overlay {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.41);
    z-index: 2;
  }

  .event-description {
    text-align: center;
    font-size: 14px;
  }
  
  @media screen and (min-width: 31.25em) {
    .planner-modal {
      width: 90%;
      padding: 2.5rem 2rem;
      text-align: center;
      top: 50%;
    }
  
    .event-success-msg {
      font-size: 1.25rem;
      text-align: center;
      margin-bottom: 12px;
      margin-top: 18px;

    }
    .event-description {
      text-align: center;
      font-size: 16px;
    }
    
    .success-modal p {
      font-size: 1rem;
      text-align: center;
    }
  }
  
  @media screen and (min-width: 47.9375em) {
    .planner-modal {
      max-width: 50rem;
      padding: 4rem 11rem;
      text-align: center;
    }
  
    .close-modal {
      top: 3rem;
      right: 6.8125rem;
    }
    
  }
  