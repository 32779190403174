.planner-modal {
  background-color: #ffffff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 1.125rem;
  width: 90%;
  margin: 0 auto;
  padding: 1.5625rem 1.375rem;
  z-index: 3;
}

.success-msg {
  font-size: 1.1rem;
  color: #1c5d99;
  margin-bottom: 1rem;
}

.success-image-container {
  margin-bottom: 1.6875rem;
  text-align: center;
  display: flex;
  justify-content: center;
}

.success-modal p {
  color: #5f5f5f;
  font-size: .875rem;
}

.modal-continue-btn {
  background-color: #1c5d99;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 0.5rem;
  border-radius: 10px;
  gap: 1rem;
  color: #ffffff;
  font-size: 1.25rem;
  width: 100%;
  cursor: pointer;
  margin-top: 1.0rem;
}

.close-modal {
  position: absolute;
  top: 1.0625rem;
  right: 0.9375rem;
  cursor: pointer;
}

.planner-modal-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.41);
  z-index: 2;
}

@media screen and (min-width: 31.25em) {
  .planner-modal {
    padding: 2.5rem 2rem;
    text-align: center;
  }

  .success-msg {
    font-size: 1.25rem;
  }

  .success-modal p {
    font-size: 1rem;
  }
}

@media screen and (min-width: 47.9375em) {
  .planner-modal {
    max-width: 50rem;
    padding: 4rem 11rem;
  }

  .close-modal {
    top: 3rem;
    right: 6.8125rem;
  }
}
