.planner-dashboard-page {
  /* height: 100vh; */
  overflow: hidden;
  display: flex;
}

.planner-dashboard {
  background-color: #f1f5fa;
  overflow: scroll;
  /* width: 100%; */
  flex: 1;
}

.planner-dashboard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.planner-dashboard-header .planner-header {
  padding-right: 0;
}

.planner-dashboard-main {
  padding: 0 1.5rem;
}

.planner-dashboard-notifications .notification {
  padding: 1rem;
  background-color: #fcfefb;
  border-radius: 8px;
  max-height: 170px;
  overflow-y: auto;
}



@media screen and (max-width: 426px) {
  .planner-dashboard-notifications .notification {
    padding: 0.75rem;
  }
}

.planner-dashboard-notifications .notification.no-notifications {
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-notifications,
.no-recent-events {
  background-color: none;
  position: relative;
  height: 15rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  text-align: center;
}

.no-notifications p,
.no-recent-events p {
  font-size: 1.25rem;
  color: #262626;
}

.planner-dashboard-notifications .notification .period {
  display: none;
}

.planner-dashboard-notifications .notification-offer span {
  background-color: #6eb424;
}

.planner-dashboard-notifications .notification-time {
  color: #6eb424;
}

.planner-dashboard .event div {
  gap: 0.2rem;
}

.planner-dashboard-recent-events {
  grid-area: planner-dashboard-recent-events;
}

.recent-events {
  height: 250px;
  overflow-y: auto;
  position: relative;
}
.recent-events.noevent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.recent-events.noevent > p {
  font-size: 1.25rem;
}

.recent-events::-webkit-scrollbar {
  width: 0.3em;
}

.recent-events::-webkit-scrollbar-thumb {
  background-color: #1c5d99;
  border-radius: 8px;
}

.recent-events::-webkit-scrollbar-track {
  background-color: #eee;
}

.planner-dashboard-page h2 {
  font-size: 1rem;
  font-weight: 400;
  color: #5f5f5f;
  margin-top: 1.25rem;
}

h2.title-event {
  margin-top: 0;
}

.planner-dashboard-subheading {
  padding: 16px 0px 16px 0px;
  font-weight: 600;
  font-size: 18px;
  color: #77797a;
}

.report-event-flag {
  display: flex;
  align-items: center;
  grid-area: report-event-flag;
  margin: 20px 0px 20px 0px;
}

.event {
  grid-area: event;
  cursor: pointer;
}

@media screen and (min-width: 48em) {
  .planner-dashboard {
    padding-bottom: 0;
  }

  .planner-dashboard-header .planner-header {
    padding-right: 1.5rem;
  }

  .planner-dashboard-header {
    display: block;
  }


  .planner-dashboard-main {
    padding: 0 2rem;
    display: grid;
    grid-template-areas:
      "event calendar-component"
      "notifications-container calendar-component"
      "planner-dashboard-recent-events calendar-component"
      "report-event-flag calendar-component";
    grid-template-columns: 2fr 0.5fr;
    gap: 1rem;
  }

  .report-event-flag {
    display: flex;
    gap: 1rem;
    color: #1c5d99;
    font-size: 1.25rem;
    cursor: pointer;
    margin-top: 2rem;
  }

  .no-notifications,
  .no-recent-events {
    padding: 1rem;
    background-color: #fff;
  }

  .no-notifications p,
  .no-recent-events p {
    font-size: 1rem;
  }
}

@media screen and (min-width: 68.75em) {
  .no-notifications,
  .no-recent-events {
    padding: 2rem;
    height: 16.5rem;
  }

  .planner-dashboard-page h2 {
    font-size: 1.25rem;
  }

  .no-notifications p,
  .no-recent-events p {
    font-size: 1.5rem;
  }
}

.planner-dashboard-main > div:nth-of-type(2){
  display: none;
}

@media screen and (min-width:769px) {
  .planner-dashboard-main > div:nth-of-type(2){
    display: flex;
  }
}

@media screen and (max-width:769px) {
  .planner-dashboard-main{
    grid-template-columns: 1fr;
  }
}
