.profile-page {
	height: 100vh;
	overflow: hidden;
	display: flex;
}

.profile-page .profile-container {
	width: 100%;
	overflow-y: auto;
}

.profile-page .profile-container .planner-header {
	justify-content: center;
}

.profile-page .profile-container .planner-header .left p {
	font-weight: 700;
}

@media screen and (min-width: 47.9375em) {
	.profile-container .profile-main {
		padding: 0 2.8125rem 2.8125rem;
	}

	.profile-page .profile-container .planner-header {
		padding-left: 2.8125rem;
		padding-right: 2.8125rem;
		justify-content: space-between;
	}
}
