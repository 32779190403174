.vendor-details-page {
	height: 100vh;
	overflow-y: hidden;
	display: flex;
}

.vendor-details {
	width: 100%;
	overflow-y: auto;
}

.vendor-details-main {
	padding: 0 1.5rem 2rem;
}

.vendor-details-heading-box-top {
	display: flex;
	align-items: center;
	margin-bottom: 1rem;
	gap: 1rem;
	justify-content: space-between;
}

.vendor-details-heading-box-top img {
	height: 5.0625rem;
	width: 5.0625rem;
	border-radius: 50%;
}

.vendor-name {
	font-size: 1.25rem;
	font-weight: 600;
	margin-bottom: 0.5rem;
}

.vendor-details-rating {
	display: flex;
	gap: 0.75rem;
	align-items: center;
	font-size: 1.125rem;
	font-weight: 600;
}

.vendor-details-rating > img{
	width:32px;
}

.vendor-details-rating > p{
	font-size: 1.5rem;
}

.vendor-detail {
	display: flex;
	justify-content: space-between;
	margin-bottom: 1.875rem;
	/* gap: 1rem; */
}

.vendor-details-heading {
	flex: 1;
	max-width: 15.1875rem;
}

.vendor-detail p:last-child{
	text-align: end;
}

.vendor-detail p:last-child {
	color: #5f5f5f;
}

.vendor-label {
	flex: 1;
	font-weight: 600;
}

@media screen and (min-width: 47.9375em) {
	.vendor-details-main {
		padding-block-start: 1rem;
		padding-inline: 1.5rem;
		/* max-width: 39.0625rem; */
		margin-top: 2rem;
	}

	.vendor-details-heading-box {
		display: flex;
		align-items: center;
		margin-bottom: 1rem;
		gap: 1rem;
		justify-content: space-between;
	}

	.vendor-details-heading-box-top {
		gap: 2rem;
	}

	/* .vendor-details-rating {
		/* width: 3.3rem; 
	} */

	.vendor-detail p:last-child {
		text-align: end;
	}
}

@media screen and (min-width: 75em) {
	.vendor-details-heading-box-top img {
		height: 6.25rem;
		width: 6.25rem;
	}

	.vendor-name {
		font-size: 1.5rem;
	}
}
