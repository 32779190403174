.create-event-heading {
  text-align: center;
  margin-bottom: 1.5rem;
}

.create-event-heading h1 {
  font-size: 1.25rem;
  margin-bottom: .5rem;
}

.create-event-heading p {
  color: #5f5f5f;
}

.create-event-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-footer-text {
  text-align: left;
  color: #FF0000;
  font-size: .875rem;
}


.date-picker:focus{
  outline:none;
  border:none
}

.event-form-input {
  margin-bottom: 0.5rem;
  width: 100%;
}

.event-form-input input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #1C5D99;
  border-radius: 4px;
  cursor: pointer;
  outline: -webkit-focus-ring-color none;
  background: white;
}

.event-form-input input:focus{
  border: 2px solid #1C5D99;
}  


.event-form-input label {
  display: block;
  margin-bottom: .5rem;
  font-weight: 600;
  text-align: left;
}

.location-input {
  position: relative;
}

.location-input img {
  position: absolute;
  right: .875rem;
  bottom: 1rem;
}