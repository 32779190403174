.planner-history-page {
	height: 100vh;
	overflow: hidden;
	display: flex;
}

.planner-history-container {
	width: 100%;
	overflow-y: auto;
}

.planner-history {
	position: relative;
	padding: 1.5rem;
	margin-bottom: 5.25rem;
	font-size: 1.25rem;
}

.history-period {
	color: #5f5f5f;
	margin-bottom: 1.25rem;
}

.history-event {
	display: flex;
	margin-bottom: 2.25rem;
	align-items: center;
	gap: 0.5rem;
	cursor: pointer;
	padding: 0.625rem;
}

.history-event img,
.vendor-history-details img {
	height: 2.5rem;
	width: 2.5rem;
	border-radius: 50%;
}

.history-event-name {
	font-size: 1rem;
	margin-right: auto;
}

.status-container {
	display: flex;
	align-items: center;
	gap: 0.5rem;
	width: 5.9375rem;
}

.status-container p {
	font-size: 0.875rem;
}

.status-container span {
	display: block;
	width: 0.625rem;
	height: 0.625rem;
	border-radius: 99px;
}

.history-event-details-container {
	position: absolute;
	top: 0;
	background-color: white;
	width: 100%;
	height: 100%;
}

.history-event-details-heading {
	display: flex;
	gap: 1rem;
	align-items: end;
	margin-bottom: 1rem;
}

.history-event-details-heading h2 {
	font-size: 1.5rem;
	font-weight: 600;
}

.close-event-btn {
	position: absolute;
	right: 1.875rem;
	cursor: pointer;
}

.vendor-history-details {
	display: flex;
	align-items: center;
	gap: 1rem;
	padding: 0.625rem 0 2rem;
}

.history-event-detail {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 2rem;
	font-size: 1.25rem;
	margin-bottom: 2.3125rem;
}

.left-details {
	font-weight: 600;
	flex: 1;
}

.right-details {
	color: #5f5f5f;
	width: 11.625rem;
}

.status-box span {
	border-radius: 200px;
	width: fit-content;
	padding: 0.625rem 1.25rem;
}

.history-box {
	max-height: 49rem;
	overflow-y: auto;
	overflow-x: hidden;
}

.history-box::-webkit-scrollbar {
	width: 0.3em;
}

.history-box::-webkit-scrollbar-thumb {
	background-color: #1c5d99;
	border-radius: 8px;
}

.history-box::-webkit-scrollbar-track {
	background-color: #eee;
}

@media screen and (min-width: 23.4375em) {
	.history-event {
		gap: 1rem;
	}
}

@media screen and (min-width: 26.25em) {
	.status-container p {
		font-size: 1rem;
	}

	.history-event-name {
		font-size: 1.25rem;
	}
}

@media screen and (min-width: 48em) {
	.planner-history-container {
		background-color: #f1f5fa;
	}

	.planner-history {
		gap: 4rem;
	}

	.history-box {
		flex-basis: 45%;
	}

	.history-event-details-container {
		position: relative;
		flex-basis: 55%;
		background-color: #f1f5fa;
	}

	.history-event-details-main {
		background-color: white;
		border-radius: 8px;
		padding: 1.875rem;
	}

	.history-event-details-heading svg {
		display: none;
	}

	.history-event-details-heading h2 {
		margin-bottom: 1rem;
	}

	.history-event-details-heading h2 {
		font-size: 1.25rem;
		font-weight: 400;
		color: #5f5f5f;
	}
}
