@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;0,800;1,600;1,800&display=swap");

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  overflow-x: hidden;
  color: #262626;
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
  box-sizing: border-box;
}


/* Modal.css */
.notification-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: flex-end; /* Align to the right */
  align-items: flex-start; /* Align to the top */
  z-index: 50;
}

.notification-modal-content {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  margin: 1rem; /* Add some margin to avoid touching the edges */
}

.modal-buttons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
  justify-content: center;
}

.btn-blue {
  background-color: #1c5d99;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.btn-blue:hover {
  background-color: #0d477e;
}

.btn-outline {
  background-color: white;
  color: #1c5d99;
  border: 2px solid #1c5d99;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.btn-outline:hover {
  background-color: #1c5d99;
  color: white;
}

div.swiper-container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.accepted {
  background-color: rgba(221, 255, 224, 0.9);
  color: #108c1c;
}
div.declined {
  background-color: #fab8b8;
  color: #f41c1c;
}
p.error {
  color: #f41c1c;
  text-align: left;
}
div.pending {
  background-color: #d2deee;
  color: #1c5d99;
}

/* ONBOARDING SCREEN CSS STYLING */
div.onboarding {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* gap: 1.0rem */
}

div.onboarding.start {
  justify-content: start;
  padding-top: 3rem;
  height: 100vh;
}

div.onboarding-splash {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1c5d99;
  color: #fff;
  z-index: 3;
  transform: translateX(0);
  animation: motionLeft 2.5s ease 1s forwards;
}

@keyframes motionLeft {
  to {
    transform: translateX(-100%);
  }
}

div.onboarding-splash > p {
  font-size: 3.75rem;
  font-weight: 700;
}

div.slider {
  position: absolute;
  top: 9vh;
  right: 70%;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

div.slider > div {
  width: 3.75rem;
  height: 0.25rem;
  border-radius: 0.625rem;
  background: #bbcde5;
}

div.slider > div.active {
  background: #1c5d99;
}

div.onboarding > svg {
  position: absolute;
  top: 8vh;
  left: 7%;
  cursor: pointer;
}

div.intro1,
div.intro2,
div.intro3 {
  max-width: 450px;
  width: 90%;
}

div.intro1-img,
div.intro2-img,
div.intro3-img {
  margin-bottom: 2rem;
  height: 170px;
  display: flex;
  justify-content: center;
}

div.intro1-container > p:nth-of-type(1),
div.intro2-container > p:nth-of-type(1),
div.intro3-container > p:nth-of-type(1) {
  color: #262626;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 0.5rem;
}


div.intro1-container > p:nth-of-type(2),
div.intro2-container > p:nth-of-type(2),
div.intro3-container > p:nth-of-type(2) {
  color: #262626;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

button.intro1-btn {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: 100%;
  padding-block: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  border: none;
  background: #1c5d99;
  cursor: pointer;
  transition: all ease 0.5s;
}

button.intro1-btn:focus{
  outline: none;
}

button.intro1-btn.disabled,
button:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

button.intro1-btn.btn {
  margin-top: 1.5rem;
}


button.intro1-btn > span {
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}

div.onboarding > p.account {
  color: #262626;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
}

div.onboarding > p.account > a {
  color: #1c5d99;
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}

p.signup-title {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 2rem;
  color: #1c5d99;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: normal;
}

div.sigup-container {
  max-width: 450px;
  width: 90%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

div.sigup-container label {
  color: #262626;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: normal;
}
p.title {
  margin-top: 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  color: #262626;
}

@media screen and (max-width: 426px) {
  p.title {
    margin-top: 1rem;
  }
}

div.sigup-container input {
  padding: 0.5rem 0 0.5rem 0.5rem;
  width: 100%;
  color: #5f5f5f;
  font-size: 16px;
  border-radius: 4px;
  background: #f1f5fa;
  margin-top: 0.5rem;
}

@media screen and (max-width: 426px) {
  div.sigup-container input {
    margin-top: 0.75rem;
  }
}

div.sigup-container > input:focus {
  outline: none;
  border: 1px solid #1c5d99;
}

div.sigup-container > input::placeholder {
  color: #5f5f5f;
  font-size: 16px;
  font-weight: 600;
}

div.signup-divider {
  margin-top: 2rem;
  display: flex;
  gap: 24px;
  justify-content: center;
  align-items: center;
}

div.signup-divider > div {
  width: 180px;
  height: 1px;
  background: #5f5f5f;
}

div.signup-divider > span {
  color: #262626;
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
}

div.google-signup,
div.apple-signup {
  cursor: pointer;
  width: 100%;
  margin-top: 12px;
  padding: 0.5rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f5fa;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

div.google-signup > svg,
div.apple-signup > svg {
  position: absolute;
  top: 50%;
  left: 13px;
  transform: translateY(-50%);
}

div.google-signup > span,
div.apple-signup > span {
  color: #262626;
  font-size: 1rem;
  font-weight: 600;
}

div.apple-signup {
  margin-bottom: 0;
  margin-top: 0;
  cursor: not-allowed;
}

div.verification-container {
  width: 90%;
  max-width: 450px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

p.verification-title {
  color: #262626;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

p.verification-mail {
  color: #262626;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

.otp-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-bottom: 0.75rem;
}

.otp-container input{
  width: 64px !important;  
  height: 55px; 
  border-radius: 4px !important;
}

@media screen and (max-width: 426px) {
  .otp-container {
    gap: 0.5rem;
  }
  .otp-container > input{
    width: 56px !important; 
    height: 47px; 
    border-radius: 4px !important
  }
}

.otp-container > input {
  border: none;
  border-radius: 4px;
  background: #f1f5fa;
}

.otp-container > input:focus {
  outline: none;
  border: 1px solid #1c5d99;
  background: transparent;
}

.verification-otp {
  margin-bottom: 2.25rem;
  color: #5f5f5f;
  font-size: 1rem;
  font-weight: 400;
}

.verification-otp > a {
  color: #1c5d99;
  text-decoration: none;
}

div.username-container,
div.instagram-container {
  width: 90%;
  max-width: 450px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

p.username-container-title,
p.instagram-title {
  color: #262626;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

form.username-form > input,
form.phonenumber-form > input {
  width: 100%;
  padding: 0.5rem 0 0.5rem 0.5rem;
  margin-bottom: 0.5rem;
  /* border: none; */
  border-radius: 4px;
  background: #f1f5fa;
}

form.username-form > input:nth-of-type(2),
form.phonenumber-form > input {
  margin-bottom: 0;
}

form.username-form > input:focus,
form.phonenumber-form > input:focus {
  outline: none;
  background: transparent;
  border: 1px solid #1c5d99;
}

div.phoneNumber-container,
div.password,
div.category {
  max-width: 450px;
  width: 90%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

div.category {
  margin-top: 0;
}

p.phonenumber-title,
p.password-title,
p.category-title {
  color: #262626;
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

div.input-password {
  border: none;
  position: relative;
}
div.input-password > input {
  height: 100%;
  width: 100%;
  padding: 0.5rem 0 0.5rem 0.5rem;
  /* border: none; */
  background: #f1f5fa;
  border-radius: 4px;
}
div.input-password > input:focus {
  outline: none;
  background: transparent;
  border: 1px solid #1c5d99;
}

.icon-container {
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}

.icon-container > svg{
  height: 100%;
  width:100%;
  cursor:pointer;
}

span.line {
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #5f5f5f;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  /* transition: all 5s ease; */
}

div.oauth {
  width: 90%;
  max-width: 450px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

div.oauth > img,
div.category > img {
  display: block;
  margin: 0 auto;
  margin-bottom: 2rem;
}

p.oauth-title,
p.category-title {
  color: #1c5d99;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  text-align: center;
}

p.oauth-desc,
p.category-desc {
  text-align: center;
  color: #262626;
  font-size: 1.75rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
}

p.category-desc {
  text-align: left;
}
div.user-type {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  /* gap:2.25rem; */
  gap: 10%;
}

div.user-type > div {
  width: 45%;
  margin-bottom: 1rem;
}

div.user-container {
  width: 100%;
  height: 9.375rem;
  border-radius: 10px;
  border: 1px solid #262626;
  margin-bottom: 0.75rem;
  transition: all ease 0.25s;
}

div.user-type > div.selected > div {
  background-color: #1c5d99;
  border: none;
}

div.user-type > div.selected > div:hover {
  outline: 3px solid #1c5d99;
  outline-offset: 3px;
}

div.vendor > p,
div.planner > p {
  text-align: center;
  color: #262626;
  font-size: 1.5rem;
  font-weight: 700;
}

div.category > select {
  width: 100%;
  padding: 1rem 0rem 1rem 1rem;
  border: 1px solid #1c5d99;
  border-radius: 4px;
  /* margin-bottom: 2.25rem; */
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../src/assets/Vector.svg") no-repeat right 1rem center;
}

@media screen and (max-width: 768px) {
  div.onboarding-splash > p {
    font-size: 2.5rem;
  }
  div.category > select{
    padding: 0.5rem 0rem 0.5rem 0.5rem;
  }
  div.intro1-container > p:nth-of-type(1),
  div.intro2-container > p:nth-of-type(1),
  div.intro3-container > p:nth-of-type(1) {
    font-size: 1.25rem;
    /* text-align: center; */
  }
  div.intro1-container > p:nth-of-type(2),
  div.intro2-container > p:nth-of-type(2),
  div.intro3-container > p:nth-of-type(2) {
    font-size: 0.75rem;
    margin-bottom: 1.25rem;
    /* text-align: center; */
  }
  button.intro1-btn > span {
    font-size: 0.75rem;
  }

  div.onboarding > p.account,
  div.onboarding > p.account > a {
    font-size: 0.85rem;
  }
  div.onboarding > svg {
    position: absolute;
    top: 5vh;
    left: 5%;
    cursor: pointer;
  }
  div.slider {
    position: absolute;
    top: 5vh;
    right: 5%;
    transform: translateY(250%);
  }

  div.sigup-container > label {
    font-size: 1.5rem;
  }

  p.verification-title {
    font-size: 1.25rem;
  }

  p.verification-mail {
    font-size: 0.75rem;
  }

  .verification-otp {
    font-size: 0.75rem;
    margin-bottom: 1.5rem;
  }
  p.username-container-title,
  p.instagram-title {
    font-size: 1.25rem;
  }

  p.phonenumber-title,
  p.password-title,
  p.category-title {
    font-size: 1.5rem;
  }

  p.oauth-desc,
  p.category-desc {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  div.user-type {
    gap: 1.5rem;
  }

  div.user-container {
    height: 9.75rem;
  }

  div.intro3-img {
    margin-bottom: 1rem;
  }

  div.intro1-img,
  div.intro2-img,
  div.intro3-img {
    margin-bottom: 1rem;
  }

  button.intro1-btn {
    margin-block: 1rem;
  }
}

@media screen and (max-width: 426px) {
  
  div.user-container {
    height: 8.75rem;
  }

  div.slider {
    position: absolute;
    top: 2vh;
  }

  div.slider {
    gap: 0.25rem;
  }

  div.slider > div {
    width: 2rem;
    height: 0.25rem;
    border-radius: 0.625rem;
    background: #bbcde5;
  }
}

/* login  page */

div.loginpage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

svg.loginpage-svg {
  position: absolute;
  top: 8vh;
  left: 7%;
  cursor: pointer;
}

div.loginpage-main {
  max-width: 450px;
  width: 90%;
}

div.loginpage > img {
  position: fixed;
  top: 8vh;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: auto;
}

h1.loginpage-heading {
  text-align: center;
  color: #1c5d99;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: normal;
  text-align: center;
}

p.login-title {
  margin-bottom: 1.25rem;
  color: #262626;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: normal;
}

form.login-form > input {
  width: 100%;
  padding: 0.5rem 0px 0.5rem 0.5rem;
  border-radius: 4px;
  background: var(--blue-light, #f1f5fa);
  margin-bottom: 0.5rem;
}

input.invalid {
  border: 1px solid #f41c1c;
}

input.correct {
  border: none;
  /* border: 1px solid #108C1C; */
}
form.login-form > input::placeholder,
div.login-password > input::placeholder,
div.resetpage > input::placeholder {
  color: #5f5f5f;
  font-size: 1rem;
  font-weight: 600;
}

form.login-form > input:focus {
  outline: none;
  border: 1px solid var(--Primary-blue, #1c5d99);
  background: transparent;
}

div.login-password {
  width: 100%;
  position: relative;
  margin-bottom: 0.5rem;
}

div.resetpage > input,
div.login-ch-password input {
  width: 100%;
  height: 100%;
  padding: 1rem 0px 1rem 1rem;
  border-radius: 4px;
  background: var(--blue-light, #f1f5fa);
  margin-bottom: 1rem;
}

div.login-password > input {
  width: 100%;
  height: 100%;
  padding: 0.5rem 0px 0.5rem 0.5rem;
  border-radius: 4px;
  background: var(--blue-light, #f1f5fa);
}

div.resetpage > input {
  margin-bottom: 0;
  margin-top: 1rem;
}

div.login-ch-password input:nth-of-type(2) {
  margin-bottom: 0;
}

div.password-criteria {
  margin-top: 1rem;
}

div.password-criteria > div {
  display: flex;
  gap: 4px;
  align-items: center;
}

div.password-criteria > div > p {
  font-size: 1rem;
  color: #262626;
}

div.login-password > input:focus,
div.resetpage > input:focus,
div.login-ch-password input:focus {
  outline: none;
  border: 1px solid var(--Primary-blue, #1c5d99);
  background: transparent;
}


div.reset-password {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

div.reset-password > div {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

div.reset-password > div > input {
  width: 16px;
  height: 16px;
}

div.reset-password > div > span,
div.reset-password > span {
  color: #1c5d99;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

div.reset-password > span {
  color: #5f5f5f;
}

.login-btn {
  border: none;
  display: flex;
  width: 100%;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  background: #1c5d99;
  color: #fff;
  font-size: 1.0rem;
  font-weight: 400;
  margin-bottom: 1.25rem;
  cursor: pointer;
}

.login-btn:hover {
  opacity: 0.75;
}

div.divider {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1.25rem;
}

div.divider > span:nth-of-type(1),
div.divider > span:nth-of-type(3) {
  max-width: 180px;
  width: 40%;
  height: 1px;
  background: #5f5f5f;
}

div.divider > span:nth-of-type(2) {
  color: #262626;
  font-size: 1rem;
  font-weight: 600;
}

div.login-with-google,
div.login-with-apple {
  width: 100%;
  padding-block: 0.5rem;
  border-radius: 4px;
  background: var(--blue-light, #f1f5fa);
  position: relative;
  text-align: center;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

div.login-with-google > svg,
div.login-with-apple > svg {
  position: absolute;
  top: 50%;
  left: 13px;
  transform: translateY(-50%);
}

div.login-with-google > span,
div.login-with-apple > span {
  color: #262626;
  font-size: 1rem;
  font-weight: 600;
}

@media screen and (max-width:768px) {
  svg.loginpage-svg {
    position: absolute;
    top: 5vh;
    left: 5%;
    cursor: pointer;
    width:20px;
    height: 20px;
}
div.loginpage > img {
  position: fixed;
  top: 5vh;
  }
}

/* forgot password section*/

p.resetpage-title {
  margin-top: 44px;
  color: #262626;
  font-size: 1.5rem;
  font-weight: 700;
}

/* div.login-otp {
  text-align: center;
} */

p.login-otp-title {
  margin-bottom: 0.5rem;
  color: #262626;
  font-size: 1.75rem;
  font-weight: 700;
}

p.login-otp-desc {
  color: #262626;
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 1.875rem;
}

p.login-otp-link {
  color: #5f5f5f;
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 2.25rem;
}

p.login-otp-link > a {
  text-decoration: none;
  color: #1c5d99;
  font-size: 1.25rem;
  font-weight: 400;
}
p.login-otp-link > a.dont-send-otp{
  color: #5f5f5f;
  cursor: not-allowed;
}
/* p.login-otp-link > a:hover{
  text-decoration: underline;
} */

p.login-ch-password-title {
  color: #262626;
  font-size: 1.75rem;
  font-weight: 700;
  margin-top: 24px;
  margin-bottom: 1.25rem;
}

/* forgot password section*/

@media screen and (max-width: 768px) {
  p.login-title,
  p.login-ch-password-title {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  .login-btn {
    font-size: 1rem;
    padding: 7.5px 0;
  }
  p.resetpage-title,
  p.login-otp-title {
    font-size: 1.5rem;
  }
  p.login-otp-desc,
  p.login-otp-link {
    font-size: 1rem;
  }
}

@media screen and (max-width:426px) {
  p.login-title,
  p.login-ch-password-title {
    margin-bottom: 0.75rem;
  }
}

/* login  page */

div.vendorpage,
div.plannerpage {
  display: flex;
  flex-direction: row;
}

/* sidebar */

div.desktop-sidebar{
  padding: 1.75rem;
  padding-inline: 1.5rem;
  color: #fff;
  background: #1c5d99;
  height: 100vh;
  position: relative;;
}
::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #84d82b; /* Set the color of the scrollbar thumb */
  border-radius: 10px; /* Set the border radius of the scrollbar thumb */
}

::-webkit-scrollbar-track {
  background-color: #eee; /* Set the color of the scrollbar track */
}



div.desktop-sidebar.closed{
  padding: 2rem;
  padding-inline:0.5rem;
  height: 100vh;
}

div.desktop-sidebar.closed ul.desktop-sidebar-list > li,
div.desktop-sidebar.closed ul.desktop-sidebar-list > li > div.settings-main {
  justify-content: center;
  align-items: center;
  gap:0
}

div.desktop-sidebar.closed > svg.sidebar-svg {
  right: 50%;
  transform: translateX(50%) translateY(15%) rotate(180deg);
  top:3.25rem;
  transition: all ease 0.5s;
}

svg.sidebar-svg {
  height: 24px;
  width:24px;
  position: absolute;
  transform: translateY(-20%);
  right:0;
  top:2rem;
  cursor: pointer;
  transition: all ease 0.5s;
}

div.desktop-sidebar-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.0rem
}

ul.desktop-sidebar-list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap:0.5rem;
}

ul.desktop-sidebar-list span{
  font-size: 0.875rem;
}

ul.desktop-sidebar-list.vendor > li:not(:nth-of-type(6)),
ul.desktop-sidebar-list.planner > li:not(:nth-of-type(4)), 
div.settings-main{
  padding: 0.5rem;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 1.0rem;
  cursor: pointer;
}

ul.desktop-sidebar-list > li > svg{
  width: 20px;
  height: 20px;
}

/* div.desktop-sidebar.planner li.settings{
  padding: 0;
  display: block;
} */

ul.desktop-sidebar-list > li.active,
ul.desktop-sidebar-list.vendor > li:hover:not(:nth-of-type(6)),
ul.desktop-sidebar-list.planner > li:hover:not(:nth-of-type(4)),
div.settings-main.active,
div.settings-main:hover,
ul.settings-dropdown > li:hover,
div.logout:hover {
  background-color: #ffffff;
  border-radius: 0.625rem;
}

ul.settings-dropdown > li.active,
ul.settings-dropdown > li:hover
{
  background-color:  #e0ffe3;
  border-radius: 0.625rem;
}

/* div.settings-main.active > svg {
  transform: rotate(0deg);
} */

ul.desktop-sidebar-list > li.active > svg > path,
ul.desktop-sidebar-list.vendor > li:hover:not(:nth-of-type(6),:nth-of-type(4)) > svg > path,
ul.desktop-sidebar-list.planner > li:hover:not(:nth-of-type(4),:nth-of-type(3) ) > svg > path,
div.settings-main.active > svg > path,
div.settings-main:hover > svg > path,
div.settings-main.active > svg > g > path,
div.settings-main:hover > svg > g > path,
ul.settings-dropdown > li.active > svg > path,
ul.settings-dropdown > li:hover > svg > path,
ul.settings-dropdown > li.active > svg > g > path,
ul.settings-dropdown > li:hover > svg > g > path,
div.logout:hover > svg > path {
  fill: #1c5d99;
  /* stroke: #1C5D99; */
}

ul.desktop-sidebar-list > li.active > svg.history > path,
ul.desktop-sidebar-list > li:hover.active > svg.history > path,
ul.desktop-sidebar-list > li:hover > svg.history > path{
  stroke: #1c5d99;
  fill: transparent
}


ul.desktop-sidebar-list > li.active:nth-of-type(4) > svg > path,
ul.desktop-sidebar-list > li:hover:nth-of-type(4) > svg > path {
  stroke: #1c5d99;
  fill: #fff;
}

ul.desktop-sidebar-list > li.active > span,
ul.desktop-sidebar-list.vendor > li:hover:not(:nth-of-type(6)) > span,
ul.desktop-sidebar-list.planner > li:hover:not(:nth-of-type(4)) > span,
div.settings-main.active > span,
div.settings-main:hover > span,
ul.settings-dropdown > li.active > span,
ul.settings-dropdown > li:hover > span,
div.logout:hover > span {
  color: #1c5d99;
  font-size: 14px;
}

ul.settings-dropdown > li > svg > g > path {
  fill: #fff;
}


ul.desktop-sidebar-list > li:last-child {
  margin-bottom: 0;
}

li.settings > div {
  border-radius: 0.625rem;
  /* background:  #F1F5FA; */
}

li.settings > div > span {
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
}

li.settings > div.settings-main > svg:nth-of-type(2) {
  transform: rotate(180deg);
  transition: all ease 0.5s;
}


li.settings > div.settings-main  svg{
  height: 20px;
  width:20px;
}

li.settings > div.settings-main.active > svg:nth-of-type(2) {
  transform: rotate(0);
}

ul.settings-dropdown {
  list-style: none;
  display: none;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

ul.settings-dropdown svg{
  width: 20px;
  height: 20px;
}

ul.settings-dropdown.active {
  display: flex;
  flex-direction: column;
  gap:0.5rem;
}
ul.settings-dropdown > li {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

ul.settings-dropdown > li > span {
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  div.desktop-sidebar.closed {
    position: relative;
  }
  div.desktop-sidebar {
    position: fixed;
    z-index: 2;
  }
}

@media screen and (max-width: 769px) {
  div.desktop-sidebar {
    display: none;
  }
}

div.mobile-sidebar {
  display: none;
}

@media screen and (max-width:769px) {
  div.mobile-sidebar{
    display: block;
    position: fixed;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100%;
}
  div.mobile-sidebar > div:nth-of-type(1){
    background-color: #fff;
    position: relative;
    width: 65%;
    height: 100%;
    padding: 1.5rem 1.5rem;
  } 
  div.image-container > img{
    width: 100px;
    height: 100px;
    border-radius: 50px;
  }  
div.image-container > p{
  margin-top: 0.75rem;
  color:  #262626;
  font-size: 1.25rem;
  font-weight: 600;
}  

ul.mobile-sidebar-list{
  margin-top:1.5rem;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap:1.0rem;
}
  ul.mobile-sidebar-list {
    margin-top: 1.5rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  ul.mobile-sidebar-list > li {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    color: #1c5d99;
    font-size: 1rem;
    font-weight: 600;
  }
/* 
div.mobile-sidebar-bottom-container{
  position: absolute;
  bottom: 1.5rem;
  left: 1rem;
} */
ul.mobile-sidebar-list svg {
    height: 32px;
    width: 32px;
  }

  div.mobile-sidebar-bottom-container {
    display: flex;
    flex-direction: column;
    gap:0.5rem
  }

  div.mobile-sidebar-bottom-container > div > svg{
    width: 32px;
    height: 32px;
  }

  div.mobile-sidebar-bottom-container > div{
    display: flex;
    gap:0.5rem;
    align-items: center;
  }

  div.mobile-sidebar-bottom-container > div > p{
    font-size: 16px;
  }
  div.mobile-sidebar-bottom-container > div:nth-of-type(1) > p{
    color: #1c5d99;
    font-weight:600;
  }
  div.mobile-switch-role {
    border-radius: 8px;
    background: #edf9e7;
    padding: 0.625rem 0.313rem;
  }

  div.mobile-switch-role > p {
    color: #262626;
    font-weight: 600;
  }

  div.mobile-sidebar-logout > p {
    color: #5f5f5f;
  }

}

@media screen and (max-width: 426px) {
  ul.mobile-sidebar-list{
    margin-top:1.0rem;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap:0.5rem;
  }

  div.image-container > p {
    font-size: 1rem;
  }
  div.mobile-sidebar-bottom-container > div > p{
    font-size: 0.75rem;
  }

  div.mobile-sidebar-bottom-container > div > svg{
    width: 24px;
    height: 24px;
  }

  ul.mobile-sidebar-list > li {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    align-items: center;
    color: #1c5d99;
    font-size: 0.75rem;
    font-weight: 600;
  }

  ul.mobile-sidebar-list svg {
    height: 24px;
    width: 24px;
  }

  iv.mobile-sidebar-logout {
    position: absolute;
    bottom: 5%;
    left: 1rem;
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    align-items: center;
  }

  div.mobile-sidebar-logout > p {
    color: #5f5f5f;
    font-size: 0.875rem;
    font-weight: 600;
  }

  div.mobile-sidebar-logout > svg {
    height: 2rem;
    width: 2rem;
  }
}

/* sidebar */

/* vendor */

div.vendorpage {
  display: flex;
  flex-direction: row;
  position: relative;
  height: 100vh;
}

/* vendor */

/* planner */

div.plannerpage {
  display: flex;
  flex-direction: row;
}

/* planner */

/* profile component */

div.profile {
  flex: 1;
  position: relative;
  padding: 1.5rem 3.25rem 2rem 2.5rem;
  overflow: scroll;
  padding-left: 48px;
}

.desktop-suggestion{
  display: block;
}
.mobile-suggestion{
  display: none;
}
@media screen and (max-width: 769px) {
  .desktop-suggestion{
    display: none
  }
  .mobile-suggestion{
    display: block;
  }
  div.profile {
    flex: 1;
    position: relative;
    padding: 3.875rem 2.75rem 3.875rem 2.75rem;
  }
}

@media screen and (max-width: 769px) {
  div.profile {
    flex: 1;
    position: relative;
    padding: 3.875rem 0rem 3.875rem 0rem;
  
  }
}

@media screen and (max-width: 426px) {
  div.profile {
    flex: 1;
    position: relative;
    padding: 1rem 0rem 2.5rem 0rem;
  }
}
div.profile-icon-container {
  position: absolute;
  right: 3.75rem;
  top: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 2rem;
}

div.profile-main-form {
  border-radius: 16px;
  background: #f1f5fa;
  margin-top: 1rem;
  padding-block: 1.875rem;
}

div.profile-main > p {
  color: #262626;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1.5rem;
}

div.profile-main-form-div {
  max-width: 650px;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

div.profile-main-form-div > div {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: end;
}

@media screen and (max-width: 1025px) {
  div.profile-main-form-div > div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

/* div.profile-main-form-div > div > div > img {
  display: block;
  max-width: 100%;
  height: auto;
} */
.profile-main-form-div .image-wrapper {
  width: 12rem; 
  height: 12rem; 
  background-color: white; 
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem; 
  box-sizing: border-box; 
}

.profile-main-form-div .image-container {
  width: 100%;
  height: 100%;
  background-color: transparent; /* Background color for the image container */
  border-radius: 50%; /* Round the image container */
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-main-form-div .profile-picture {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

div.profile-main-form-div > div > div:nth-of-type(2) {
  display: flex;
  flex-direction: row;
  padding-bottom: 41px;
  gap: 4rem;
}

div.profile-main-form-div > div > div:nth-of-type(2) > span {
  color: #1c5d99;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: normal;
}

div.profile-main-form-div > div > div:nth-of-type(2) > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

div.star-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
div.profile-main-form-div > div > div:nth-of-type(2) > div > span {
  color: #000;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: normal;
}

div.profile-main-form-div > form > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

div.profile-main-form-div > form > div > div {
  /* max-width: 300px; */
  width: 45%;
}

div.profile-main-form-div > form > div > div > label {
  margin-bottom: 1rem;
  color: #262626;
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
}

div.input-container {
  position: relative;
  width: 100%;
  height: 2.25rem;
  margin-top: 0.5rem;
}

div.input-container > input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 11px 14px;
  border-radius: 4px;
  border: none;
}

div.input-container > input:focus {
  outline: none;
}

div.input-container > svg {
  position: absolute;
  top: 50%;
  right: 0.875rem;
  transform: translateY(-50%)
}

div.profile-main-form-div > form > div > div > select {
  width: 100%;
  border: none;
  border-radius: 4px;
  padding: 0.375rem 0.375rem;
  height: 36px;
  appearance: none;
  background: url("../src/assets/Vector.svg") no-repeat right 0.875rem center;
  background-color: #fff;
  margin-top: 0.5rem;
}

div.profile-main-form-div > form > div > div > select:focus {
  outline: none;
}

div.profile-main-form-div > form > div:nth-of-type(4) {
  margin-bottom: 1rem;
}

div.profile-main-form-div > form > button {
  width: 100%;
  border: none;
  border-radius: 10px;
  padding: 11px 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
  background: #1c5d99;
}

div.vendor.closed div.logout,
div.planner.closed div.logout {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66px;
}

div.logout {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1.5rem;
  padding: 0.4rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

div.switch-role {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  padding: 0.4rem;;
  border-radius: 4px;
  background: #fff;
  color: #262626;
  /* margin-top: 0.5rem; */
  cursor: pointer;
  width: 158.4px;
}

div.switch-role svg, div.logout svg{
  width:24px;
  height: 24px;
}

div.switch-role > p{
  color:  #262626;
  font-size: 0.875rem;
  font-weight: 600;
}

div.logout > span {
  color: #f5f3f3;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
}

svg.arrow-back {
  display: none;
}
@media screen and (max-width: 1025px) {
  div.profile-icon-container {
    display: none;
  }
  div.profile-main > p {
    text-align: center;
    color: #262626;
    font-size: 24px;
    font-weight: 700;
    line-height: nrmal;
  }
  div.profile-main-form-div > div > div:nth-of-type(2) {
    flex-direction: column;
    padding-bottom: 0;
    gap: 1rem;
    justify-content: center;
    align-items: center;
  }

  div.profile-main-form-div > form > div {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  div.profile-main-form-div > form > div > div {
    width: 100%;
  }

  div.profile-main-form-div > div > div:nth-of-type(2) > span,
  div.profile-main-form-div > div > div:nth-of-type(2) > div > span,
  div.profile-main-form-div > form > div > div > label {
    font-size: 16px;
  }

  .upload-input {
    cursor: pointer;
    color: #1c5d99;
    font-weight: 900;
  }

  div.profile-main-form-div > form > button {
    font-size: 1rem;
  }

  div.star-container > svg {
    height: 16px;
    width: 16px;
  }

  div.input-container {
    height: 2.5rem;
  }
}

/* profile component */

/* mobile sidebar */

div.sidebar,
div.settings-menu {
  display: none;
}

@media screen and (max-width: 769px) {
  svg.arrow-back {
    display: block;
    position: absolute;
    top: 2.25rem;
    left: 5%;
  }
div.sidebar{
  display: block;
  position: fixed;
  width: 100%;
  bottom: 0;
  left:0;
  padding: 1rem 2rem;
  background-color: #fff;
  z-index: 3;
}
  ul.sidebar-list {
    list-style: none;
    /* width: 100%; */
    display: flex;
    /* gap: 3.125rem; */
    justify-content: space-between;
  }
/* ul.sidebar-list > li{
  display: flex;
  flex-direction: column;
  gap:0.625rem;
  justify-content: center;
  align-items: center;
} */

  ul.sidebar-list > li.active > span {
    color: #1c5d99;
  }

  ul.sidebar-list > li.active > svg > path {
    fill: #1c5d99;
  }

  ul.sidebar-list > li.active > svg > g > path {
    stroke: #1c5d99;
  }

ul.sidebar-list > li > svg{
  height: 32px;
  width: 32px;
  }

  ul.sidebar-list {
    list-style: none;
    /* width: 100%; */
    display: flex;
    /* gap: 3.125rem; */
    justify-content: space-between;
  }

  ul.sidebar-list > li {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    justify-content: center;
    align-items: center;
  }

  ul.sidebar-list > li.active > span {
    color: #1c5d99;
  }

  ul.sidebar-list > li.active > svg > path {
    fill: #1c5d99;
  }

  ul.sidebar-list > li.active > svg > g > path {
    stroke: #1c5d99;
  }

  /* ul.sidebar-list > li > svg {
    height: 48px;
    width: 48px;
  } */

  ul.sidebar-list > li > span {
    color: #8d8d8d;
    font-size: 1rem;
    font-weight: 600;
  }

  div.settings-menu {
    width: 90%;
    margin: 2.25rem auto;
    display: block;
  }

  div.settings-menu > p {
    color: #262626;
    font-size: 24px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 2.5rem;
  }

  ul.settings-menu-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
  }

  ul.settings-menu-list > li {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  ul.settings-menu-list > li > div {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }

  ul.settings-menu-list > li > div > span {
    color: #262626;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: normal;
  }
}

@media screen and (max-width: 426px) {
  div.sidebar {
    padding: 0.5rem 1rem 0.5rem 1rem;
  }
  ul.sidebar-list > li > svg {
    height: 24px;
    width: 24px;
  }
  ul.sidebar-list > li > span {
    font-size: 0.75rem; 
  }
}

/* mobile sidebar */

/* Dashboard vendor component */

div.dashboard-main {
  padding: 2rem 1.5rem;
  padding-right: 3.25rem;
  flex: 1;
  background-color: #f1f5fa;
  overflow-y: scroll;
}

div.dashboard-main-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

div.dashboard-main-heading > div {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
}

div.dashboard-main-heading > div > svg {
  cursor: pointer;
}

div.dashboard-main-heading > div > p {
  color: #262626;
  font-family: Nunito;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: normal;
}

div.dashboard-main-content {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  margin-top: 1rem;
}

div.dashboard-main-content > div:nth-of-type(1) {
  flex: 3;
}

div.calender-component-1 {
  display: block;
  flex: 1;
  height: full;

}


div.event {
  cursor: pointer;
  padding: 1.25rem 1.75rem;
  color: #fff;
  border-radius: 8px;
  background: linear-gradient(93deg, #1c5d99 -1.07%, #6893bb 98.48%);
  margin-bottom: 2rem;
}

div.event > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
}

div.event > div > span {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: normal;
}

div.event > p {
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}

div.token {
  padding: 2.5rem 1.75rem;
  border-radius: 0.5rem;
  background: linear-gradient(264deg, #eaf7e2 7.91%, rgba(255, 255, 255, 0.99) 98.49%);
  margin-bottom: 2rem;
}

.wallet-wrapper {
  overflow: hidden;
}

.wallet-wrapper {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.wallet-wrapper::-webkit-scrollbar {
  display: none;
}

div.token > div:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}

div.token > div:nth-of-type(1) > p {
  color: #1c5d99;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: normal;
}

div.token > div:nth-of-type(1) > p > span {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: normal;
}

div.token > div{
  display: flex;
  gap:1.5rem
}

div.token > div > button:nth-of-type(1) {
  border: none;
  padding: 0.625rem;
  padding-inline: 1rem;
  border-radius: 0.25rem;
  background: #1c5d99;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}

div.token > div > button:nth-of-type(1):hover{
  border: 1px solid #1c5d99;
  color: #1c5d99;
  background-color: transparent;
  transition: all ease-in 0.5s;
}

div.token > div > button:nth-of-type(2) {
  padding: 0.625rem;
  padding-inline: 1rem;
  border-radius: 0.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
  border: 1px solid #1c5d99;
  color: #1c5d99;
  background-color: transparent;
}

div.token > div > button:nth-of-type(2):hover{
  background: #1c5d99;
  color: #fff;
  transition: all ease-in 0.5s;
}

div.order-request {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.625rem;
}

div.order-request > span {
  color: #77797a;
  font-size: 1.25rem;
  font-weight: 600;
}

div.order-request > a {
  color: #1c5d99;
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
}

div.table-container {
  padding: 1.0rem 0.5rem;
  background: #fff;
  margin-bottom: 3rem;
  border-radius: 0.5rem;
}

div.table-container.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 12.5rem;
}
div.no-offer {
  text-align: center;
}
div.no-offer > p {
  font-size: 20px;
  font-weight: 400;
  color: #262626;
}

div.no-offer > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

div.no-offer > div > p {
  font-size: 12px;
}

div.dashboard-main-content table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
}

div.dashboard-main-content table > thead > tr:nth-of-type(1) {
  border-radius: 0.25rem;
  background: #f1f5fa;
  padding-left: 2rem;
}

div.dashboard-main-content table > tbody > tr:hover {
  cursor: pointer;
  background-color: #f1f5fa;
}

div.dashboard-main-content table > thead > tr:nth-of-type(1) > th {
  color: #262626;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
  padding-block: 0.563rem;
  text-align: left;
  overflow-wrap: break-word;
}

div.dashboard-main-content table > tbody > tr:nth-of-type(1) > th:nth-of-type(1) {
  padding-left: 2rem;
}

div.dashboard-main-content table > tbody > tr > td:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  /* margin-left: 2.25rem; */
}

div.dashboard-main-content table > tbody > tr > td:nth-of-type(1) span {
  color: #262626;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: normal;
  overflow-wrap: break-word;
}

div.dashboard-main-content table > tbody > tr > td {
  padding-block: 0.5rem;
  color: #262626;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: normal;
}

div.dashboard-main-content table > tbody > tr:nth-of-type(2) > td {
  padding-top: 1rem;
  overflow-wrap: break-word;
}

div.report {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.625rem;
  cursor: pointer;
}

div.report > span {
  color: #1c5d99;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: normal;
}

div.calender-component-1 {
  border-radius: 0.625rem;
  background: #eaf7e2;
  padding: 16px;
  position: relative;
}

img.calender-svg {
  position: absolute;
  top: 24px;
  right: 20.5px;
  cursor: pointer;
}

div.calender-component-1 > p:nth-of-type(1) {
  color: #262626;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.75rem;

}

div.calender-component-1 > p:nth-of-type(2) {
  color: #5f5f5f;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.75rem;
}

div.event-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.5rem;
  min-height: 250px;
}


div.upcoming-event-v {
  padding-bottom: 1.25rem;

}

div.upcoming-event-v:nth-of-type(1) {
  margin-bottom: 0.5rem;
  border-bottom: 1px solid rgba(132, 216, 43, 0.3);
}

div.upcoming-event-v:nth-of-type(2) {
  margin-bottom: 1.5rem;
}

div.upcoming-event-v > p {
  color: #5f5f5f;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 1.25rem;
}

div.upcoming-event-v > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  gap: 0.75rem;
}

div.upcoming-event-v > div > div:nth-of-type(1) {
  width: 1px;
  height: 42px;
  background: #133e66;
  align-self: stretch;
}

div.upcoming-event-v > div > p {
  color: #262626;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}


div.upcoming-event-v > div > div:nth-of-type(2) > p:nth-of-type(1) {
  color: #5f5f5f;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0.25rem;
}

div.upcoming-event-v > div > div:nth-of-type(2) > p:nth-of-type(2) {
  color: #133e66;
  font-size: 1.25rem;
  line-height: normal;
}
div.event-mobile{
  display: none;
}

@media screen and (max-width: 1201px) {
  div.event > div > span {
    font-size: 1.25rem;
  }
  div.event > div > svg {
    height: 27px;
    width: 27px;
  }
  div.event > p {
    font-size: 1rem;
  }
  div.event {
    padding: 1rem 1.5rem;
    color: #fff;
    border-radius: 8px;
    margin-bottom: 1.75rem;
  }
  div.token {
    padding: 2.25rem 1.5rem;
    border-radius: 0.5rem;
    background: linear-gradient(264deg, #eaf7e2 7.91%, rgba(255, 255, 255, 0.99) 98.49%);
    margin-bottom: 1.75rem;
  }
  div.token > div:nth-of-type(1) {
    gap: 1.25rem;
    margin-bottom: 1.25rem;
  }
  div.token > div:nth-of-type(1) > p {
    font-size: 1rem;
  }
  div.token > div:nth-of-type(1) > p > span {
    font-size: 1.25rem;
  }
  div.token > div > button:nth-of-type(1) {
    font-size: 0.75rem;
    font-weight: 400;
    line-height: normal;
  }
  div.token > div > button:nth-of-type(2) {
    font-size: 0.75rem;
  }

  div.order-request > span {
    font-size: 1rem;
  }

  div.order-request > a {
    font-size: 0.75rem;
  }

  div.dashboard-main-content table > thead > tr:nth-of-type(1) > th:nth-of-type(1) {
    padding-left: 1.75rem;
  }

  div.dashboard-main-content table > thead > tr:nth-of-type(1) > th {
    font-size: 0.75rem;
  }

  div.dashboard-main-content table > tbody > tr > td:nth-of-type(1) {
    margin-left: 1.5rem;
  }

  div.dashboard-main-content table > tbody > tr > td:nth-of-type(1) span {
    font-size: 0.75rem;
  }

  div.dashboard-main-content table > tbody > tr:nth-of-type(2) > td {
    padding-top: 0.75rem;
  }

  div.dashboard-main-content table > tbody > tr:not(:nth-of-type(1)) > td {
    font-size: 0.75rem;
  }

  div.table-container {
    padding: 1.113rem 0.5rem;
    background: #fff;
    margin-bottom: 2.5rem;
  }

  div.calender-component-1 > p:nth-of-type(1) {
    font-size: 1.25rem;
  }

  div.calender-component-1 > p:nth-of-type(2) {
    font-size: 0.75rem;
  }

  div.upcoming-event-v > p {
    font-size: 0.75rem;
  }

  div.upcoming-event-v > div > p {
    font-size: 0.75rem;
  }

  div.upcoming-event-v > div > div:nth-of-type(2) > p:nth-of-type(1) {
    font-size: 0.75rem;
  }

  div.upcoming-event-v > div > div:nth-of-type(2) > p:nth-of-type(2) {
    font-size: 1rem;
  }
}

@media screen and (max-width: 1025px) {
  div.dashboard-main {
    padding: 2rem 1.5rem;
  }
}

@media screen and (max-width: 900px) {
  div.dashboard-main-content {
    gap: 1rem;
  }
  .react-calendar {
    width: 18.75rem;
  }
}

@media screen and (max-width: 769px) {
  div.table-container.empty{
    min-height: 9rem;
  }
  div.calender-component-1{
    display: none;
  }
  div.dashboard-main-heading > div {
    gap: 1rem;
  }
  div.dashboard-main-heading > div > p {
    font-size: 1.25rem;
  }
  div.dashboard-main-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
  div.dashboard-main {
    padding: 2rem 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 7.5rem;
  }

  div.event-mobile {
    display:block;
    margin-bottom: 0.625rem;
  }

  div.event-empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
  }

  div.event-empty > p:nth-child(1) {
    color: #133e66;
    font-size: 1rem;
    font-weight: 500;
  }

  div.event-empty > p:nth-child(2) {
    font-size: 0.75rem;
    color: #133e66;
    font-weight: 400;
  }

  div.event-mobile > div:nth-of-type(1) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 2.25rem;
  }

  div.event-mobile > div:nth-of-type(2) {
    min-height: 10rem;
  }

  div.event-mobile > div > p {
    color: #5f5f5f;
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
  }

  div.event-mobile > div > div.sub-event {
    display: flex;
    flex-direction: row;
    gap: 1.125rem;
  }

  div.event-mobile > div:nth-of-type(2) {
    padding: 0.875rem 1.25rem;
    border-radius: 0.625rem;
    background: #f7fcf5;
  }

  div.eventday-mobile {
    padding-bottom: 1.25rem;
    border-bottom: 2px solid #cbeeab;
  }

  div.eventday-mobile:nth-of-type(1) {
    margin-bottom: 0.5rem;
  }

  div.eventday-mobile > p {
    color: #5f5f5f;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 1rem;
  }

  div.eventday-mobile > div {
    display: flex;
    align-items: center;
    position: relative;
    gap: 0.75rem;
  }

  div.eventday-mobile > div > p {
    color: #262626;
    font-size: 1rem;
    font-weight: 400;
    line-height: normal;
  }

  div.eventday-mobile > div > div:nth-of-type(1) {
    height: 2.938rem;
    width: 0.125rem;
    background: #133e66;
  }

  div.eventday-mobile > div > div:nth-of-type(2) > p:nth-of-type(1) {
    color: #5f5f5f;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  div.eventday-mobile > div > div:nth-of-type(2) > p:nth-of-type(2) {
    color: #133e66;
    font-size: 1rem;
    font-weight: 400;
    line-height: normal;
  }

  div.report > span {
    color: #1c5d99;
    font-size: 1rem;
  }
}

@media screen and (max-width: 426px) {
  div.no-offer > p{
    font-size: 16px;
  }
  div.no-offer > div > img{
    width: 24px;
  }
  div.dashboard-main {
    padding: 1.0rem 1rem;
    padding-bottom: 7.5rem;
  }
  
  div.dashboard-main-content table > tbody > tr > td:nth-of-type(1) {
    gap: 0.25rem;
  }
  div.dashboard-main-content table >  thead > tr:nth-of-type(1) > th:nth-of-type(1) {
    padding-left: 0rem;
  }
  div.dashboard-main-content table >  tbody > tr > td:nth-of-type(1) {
    margin-left: 0rem;
  }
  div.dashboard-main-content table >  tbody > tr > td:nth-child(2){
    text-align: center;
  }
  div.dashboard-main-content table >  tbody > tr > td:nth-child(4){
    text-align: end;
  }
  div.table-container {
    padding:0rem 0.5rem;
    margin-bottom: 1.0rem;
  }
  div.dashboard-main-content table > thead > tr:nth-of-type(1) {
    display: none;
  }
  div.dashboard-main-content table > tbody > tr > td:nth-of-type(3) {
    display: none;
  }
}

/* Dashboard vendor component */

/* orderrequest component */

div.orderrequest-page {
  flex: 1;
  padding: 2rem 1.5rem;
  padding-right: 3.25rem;
  background: #f1f5fa;
  overflow-y: scroll;
}

div.orderrequest-main {
  position: relative;
}
div.top-right {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  gap: 32px;
  align-items: center;
}

div.orderrequest-main > p {
  padding-top: 2rem;
  color: #262626;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.5rem;
}

div.selection-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 1rem;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1.313rem;
}
div.selection-container.small-screen{
  display: none
}
div.selection-container > div {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 10.5rem;
}

div.selection-container > div > label {
  color: #5f5f5f;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}

.wrap select {
  outline: none;
  width: 100%;
  background: transparent;
}
.wrap {
  padding: 6px 6px;
  border-radius: 0.25rem;
  border: 0.0625rem solid var(--Primary-blue, #1c5d99);
  background: transparent;
}

div.selection-container > button {
  width: 11.25rem;
  padding: 0.625rem;
  text-align: center;
  border-radius: 4px;
  background: #1c5d99;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: normal;
  align-self: self-end;
}

div.order-sometimes {
  padding: 1.0rem 1.5rem 1.0rem 1.5rem;
  border-radius: 10px;
  background: #fff;
}

div.orderrequest-btn{
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

div.orderrequest-btn > button{
  background-color: #1C5D99;
  color:white;
  padding: 0.5rem 5rem;
  border-radius: 0.5rem;
  font-size: 1rem;
}

div.order-sometimes.norequest {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

div.order-sometimes.norequest > div > p {
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

div.order-sometimes.norequest > div > div {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

div.order-sometimes.norequest > div > div > p {
  font-size: 1rem;
}

div.order-sometimes > table  tr:hover {
  background-color: #f1f5fa;
  border-radius: 4px;
}
div.order-sometimes.offer {
  display: block;
  margin-top: 1rem;
}

div.order-sometimes > table {
  width: 100%;
  border-collapse: collapse;
}

div.order-sometimes > table > thead > tr:nth-of-type(1) {
  border-radius: 4px;
  background: #f1f5fa;
}

div.order-sometimes > table > thead > tr:nth-of-type(1) > th:nth-of-type(1) {
  padding-left: 1.75rem;
  color: #262626;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: normal;
  text-align: start;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

div.order-sometimes > table > thead > tr:nth-of-type(1) > th:last-child{
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

div.order-sometimes > table > thead > tr:nth-of-type(1) > th {
  color: #262626;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: normal;
  text-align: start;
  padding-block: 0.5rem;
}

div.order-sometimes > table > tbody > tr > td:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  padding-left: 0.5rem;
  align-items: center;
}



div.order-sometimes > table > tbody > tr > td:nth-of-type(1) > p {
  color: #262626;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}

div.order-sometimes.offer > table > tbody > tr > td:nth-of-type(1) > p {
  padding-block: 0.625rem;
}

div.order-sometimes > table > tbody > tr > td {
  color: #262626;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
  padding-block: 0.5rem;
}

svg.back-icon {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(50%);
  cursor: pointer;
}

div.order-sometimes > table > tbody > tr td {
  cursor: pointer;
}

div.order-sometimes > table > tbody > tr td:last-child {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* gap: 1rem; */
}

div.order-sometimes > table > tbody > tr > td:last-child > p{
  font-size: 1rem;
}
div.order-mobile,div.order-mobile.norequest{
  display: none;
}
@media screen and (max-width: 769px) {
  div.order-sometimes{
    display:none
  }
  div.order-sometimes.norequest{
    display: none;
  }
  div.orderrequest-page {
    padding: 1.75rem 1.5rem;
    padding-bottom: 6.5rem;
  }
  div.orderrequest-main > p {
    padding-top: 0rem;
    margin-bottom: 2rem;
  }

  div.order-mobile {
    margin-top: 0.75rem;
    padding-block: 1rem;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  div.selection-container.big-screen{
    display: none;
  }

  div.selection-container.small-screen{
    display: flex;
    justify-content: flex-start;
  }

  div.order-mobile.norequest{
    display: flex;
    min-height: 200px;
  }

  div.order-mobile.norequest > div {
    text-align: center;
  }

  div.order-mobile.norequest > div > p {
    font-size: 1rem;
    font-weight: 400;
  }

  div.order-mobile.norequest > div > div {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  div.order-mobile.norequest > div > div > p {
    font-size: 0.75rem;
  }

  div.order-mobile > table {
    width: 100%;
    border-collapse: collapse;
  }

  div.order-mobile > table > thead > tr:nth-of-type(1) {
    background-color: #f1f5fa;
  }

  div.order-mobile > table > thead > tr:nth-of-type(1) > th {
    text-align: start;
    color: #262626;
    font-size: 1rem;
    font-weight: 400;
    padding-block: 0.625rem;
  }

  div.order-mobile > table > thead > tr:nth-of-type(1) > th:nth-of-type(1),
  div.order-mobile > table > tbody > tr > td:nth-of-type(1) {
    padding-left: 1.0rem;
  }

  div.order-mobile > table > tbody > tr > td:last-child {
    padding-right: 1.0rem;
  }

  div.order-mobile > table > tbody > tr > td {
    color: #262626;
    font-size: 1rem;
    font-weight: 400;
  }

  div.order-mobile > table > tbody > tr:nth-of-type(2) > td {
    padding-block: 0.625rem;
  }


  div.order-mobile > table > tbody > tr > td:nth-of-type(2) > svg{
    display: inline;
    margin-right: 0.5rem;
  }

  div.order-mobile > table > tbody > tr > td {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  div.order-mobile > table > tbody > tr td:nth-of-type(3){
    display: flex;
    justify-content: space-between;
    align-items: center;
  } 
  div.order-mobile > table > tbody > tr td {
    cursor: pointer;
  }
  div.order-mobile > table > tbody > tr td > svg{
    display: inline;
  }
}

@media screen and (max-width: 425px) {
  div.orderrequest-page {
    padding: 1rem 1rem;
    padding-bottom: 6.5rem;
  }
  div.order-mobile > table > thead > tr:nth-of-type(1) > th {
    font-size: 0.75rem;
  }
  div.order-mobile > table > tbody > tr > td {
    font-size: 0.75rem;
  }
  div.order-mobile > table > thead > tr:nth-of-type(1) > th:nth-of-type(1),
  div.order-mobile > table > tbody > tr > td:nth-of-type(1) {
    padding-left: 0.5rem;
  }
  div.order-mobile > table > tbody > tr > td:last-child {
    padding-right: 0.5rem;
  }

  div.order-mobile.norequest > div > div > img {
    width: 24px;
    height: 24px;
  }
}

/* request component */

div.request-component {
  display: flex;
  justify-content: center;
  align-items: center;
}

div.request-component-main {
  max-width: 600px;
  width: 90%;
}

div.img-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

div.img-container > img {
  width: 2.5rem;
  height: 2.5rem;
}

div.img-container > p {
  color: #262626;
  font-size: 1.25rem;
  font-weight: 600;
}

@media screen and (max-width: 426px) {
  div.img-container > p {
    font-size: 1rem;
  }
  div.img-container > img {
    width: 2rem;
    height: 2rem;
  }
}

div.description-container {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

div.desc > p {
  color: #262626;
  font-size: 1.125rem;
  line-height: normal;
}

div.desc > span {
  color: #5f5f5f;
  font-size: 1rem;
  font-weight: 00;
  line-height: normal;
}

div.cta {
  position: relative;
  width: 100%;
  height: 3rem;
  border-radius: 10px;
  background: #e6f7d5;
  display: flex;
  flex-direction: row;
  gap: 25%;
  margin-bottom: 1rem;
}

div.cta > input {
  width: 100%;
  height: 100%;
  padding: 0.75rem;
  border: none;
  background-color: transparent;
  text-align: center;
  font-size: 1.45rem;
  font-weight: 600;
}

div.cta > input:focus {
  outline: none;
}

div.circle {
  position: absolute;
  transform: translateY(-50%);
  display: flex;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  z-index: 2;
  cursor: pointer;
}

div.circle > svg {
  height: 0.875rem;
  width: 0.875rem;
}

div.circle:nth-of-type(1) {
  left: 5%;
  top: 50%;
}

div.circle:nth-of-type(2) {
  right: 5%;
  top: 50%;
}

button.btn-request {
  border: none;
  margin: 0 auto;
  width: 100%;
  max-width: 450px;
  padding-block: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 0.625rem;
  background: #1c5d99;
}
@media screen and (max-width: 769px) {
  div.desc > span {
    font-size: 1rem;
  }
  div.img-container {
    position: relative;
  }
  div.img-container > svg {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(50%);
    cursor: pointer;
  }
  div.cta {
    height: 2.5rem;
  }
  div.circle {
    width: 25px;
    height: 25px;
  }
  div.cta > input {
    padding: 1rem;
    border: none;
    background-color: transparent;
    text-align: center;
    font-size: 1rem;
  }
}

@media screen and (max-width: 426px) {
  div.cta > input {
    padding: 0.75rem;
    border: none;
    background-color: transparent;
    text-align: center;
  }
  div.request-component{
    margin-top: 0.25rem;
  }
}

/* request component modal */

div.request-modal {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.41);
  display: flex;
  justify-content: center;
  align-items: center;
}

div.request-modal > div {
  max-width: 500px;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  background-color: #fff;
  padding: 5.25rem 2rem;
  border-radius: 0.625rem;
}

div.request-modal > div > img {
  width: 12.25rem;
  height: 12.688rem;
}

div.request-modal > div > p {
  color: #1c5d99;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: normal;
  text-align: center;
}

/* div.request-modal > div > p.para01{
  margin-bottom: 2.5rem;
} */

div.request-modal > div > div {
  display: flex;
  gap: 5%;
}

div.request-modal > div > div > button {
  width: 47.5%;
  padding: 0.625rem;
  padding-inline: 2.5rem;
  background-color: transparent;
}
div.request-modal > div > div > button:nth-of-type(1) {
  border-radius: 8px;
  border: 1px solid #1c5d99;
  color: #1c5d99;
}

div.request-modal > div > div > button:nth-of-type(2) {
  border-radius: 8px;
  border: none;
  background: #1c5d99;
  color: #fff;
}

@media screen and (max-width: 769px) {
  div.request-modal > div {
    padding: 2.25rem 1.5rem;
    border-radius: 0.625rem;
  }
}

@media screen and (max-width: 426px) {
  div.order-mobile > table > tbody > tr > td {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
  }
}

/* request component modal */

/* request component */

/* orderrequest component */

/* offer component */
div.offer-page {
  flex: 1 1;
  padding: 2rem 1.5rem;
  padding-right: 3.25rem;
  background: #f1f5fa;
  overflow-y: scroll;
}

div.offer-main {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
div.no-offer-page {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

div.no-offer-page > p:nth-of-type(1) {
  font-size: 1.5rem;
  font-weight: 700;
  color: #1c5d99;
}

div.no-offer-page > p:nth-of-type(2) {
  color: #1c5d99;
  font-size: 1rem;
  font-weight: 400;
}

div.offer-main > p {
  padding-top: 2rem;
  color: #262626;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 2.5rem;
}

td.accepted > div,
td.pending > div,
td.declined > div {
  padding-block: 0.5rem;
  padding-inline:1.5rem;
  text-align: center;
  border-radius: 200px;
  /* background: rgba(221, 255, 224, 0.9);
  color: #108c1c; */
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}

td.pending > div {
  border-radius: 200px;
  background: #d2deee;
  color: #1c5d99;
}

td.declined > div {
  border-radius: 200px;
  background: #fab8b8;
  color: #f41c1c;
}

div.order-sometimes.offer > table > tbody > tr > td {
  color: #262626;
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}

div.offer-component-main {
  padding-top: 3.5rem;
}

div.offer-component-main > .img-container {
  margin-bottom: 1.25rem;
}

div.offer-container {
  display: flex;
  flex-direction: row;
  gap: 2.25rem;
}

div.offer-container > div {
  flex: 1;
}

div.offer-container > div:nth-of-type(1) {
  padding: 0.625rem;
  background: #fff;
  border-radius: 0.5rem;
}

div.offer-container > div:nth-of-type(2) {
  align-self: center;
}

div.offer-container > div:nth-of-type(2) > div {
  padding-block: 1.5rem;
  padding-inline: 0.5rem;
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

div.offer-container > div:nth-of-type(2) > div > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

div.offer-container > div:nth-of-type(2) > div > div > p:nth-of-type(1) {
  color: #262626;
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
}

div.offer-container > div:nth-of-type(2) > div > div > p:nth-of-type(2) {
  font-size: 1rem;
  font-weight: 400;
  line-height: normal;
}

div.offer-container > div:nth-of-type(2) > p {
  color: #262626;
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

div.offer-container > div:nth-of-type(2) > button {
  border: none;
  display: flex;
  max-width: 250px;
  width: 100%;
  padding-block: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 0.625rem;
  background: #1c5d99;
  color: #fff;
  font-size: 1rem;
}
div.offer-mobile{
  display: none;
}

div.no-offer-page.mobile{
  display:none;
}

@media screen and (max-width: 769px) {
  div.no-offer-page.mobile{
    display: flex;
  }
  div.no-offer-page.desktop{
    display: none;
  }
  div.order-sometimes.offer{
    display: none;
  }
  div.offer-container > div:nth-of-type(2) > div {
    padding-block: 1rem;
    padding-inline: 0.5rem;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.25rem;
  }
  div.offer-main > p {
    padding-top: 0rem;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1.313rem;
  }
  div.offer-mobile {
    display: block;
    border-radius: 0.25rem;
    margin-top: 1rem;
    background-color: #fff;
  }
  div.offer-mobile > div {
    padding: 0.5rem 1.25rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  div.offer-mobile > div > div:nth-of-type(1) {
    display: flex;
    flex-direction: row;
    align-items: center;

    gap: 0.5rem;
  }

  div.offer-mobile > div > div > p {
    color: #262626;
    font-size: 1rem;
    font-weight: 400;
    white-space: nowrap;
  }

  div.offer-status {
    padding: 0.5rem;
    /* padding-inline: 0.75rem; */
    text-align: center;
    width: 150px;
    font-size: 1rem;
    font-weight: 400;
  }
  div.accepted {
    border-radius: 100px;
    background: #ddfde2;
  }
  div.accepted > span {
    color: #108c1c;
  }

  div.declined {
    border-radius: 100px;
    background: #fab8b8;
  }

  div.declined > span {
    color: #f41c1c;
  }

  div.pending {
    border-radius: 200px;
    background: #d2deee;
  }

  div.pending > span {
    color: #1c5d99;
  }
  div.offer-component-main {
    padding-top: 0rem;
  }
  div.offer-component-main > .img-container {
    margin-bottom: 1.875rem;
  }

  div.offer-container {
    display: flex;
    flex-direction: column-reverse;
    gap: 2.25rem;
  }
  div.offer-container > div:nth-of-type(2) {
    align-self: stretch;
  }

  div.offer-container > div:nth-of-type(2) > div > div > p:nth-of-type(1) {
    font-size: 1.25rem;
  }
  div.offer-container > div:nth-of-type(2) > div > div > p:nth-of-type(2) {
    font-size: 1rem;
  }

  div.offer-container > div:nth-of-type(2) > p {
    font-size: 1rem;
  }
  div.offer-container > div:nth-of-type(2) > button {
    font-size: 14px;
    border: none;
  }
  div.offer-page {
    flex: 1 1;
    padding: 2rem 1.5rem;
    background: #f1f5fa;
  }
}
div.offer-container > div:nth-of-type(2) > button {
  font-size: 14px;
}

@media screen and (max-width: 426px) {
  div.offer-page {
    flex: 1 1;
    padding: 1rem 1rem;
    background: #f1f5fa;
  }
  div.offer-mobile > div {
    padding: 0.5rem 0.5rem;
  }
  div.offer-mobile > div > div > p {
    color: #262626;
    font-size: 0.85rem;
  }
  div.offer-container > div:nth-of-type(2) > div > div > p:nth-of-type(1) {
    font-size: 1rem;
  }
  div.offer-container > div:nth-of-type(2) > div > div > p:nth-of-type(2) {
    font-size: 0.75rem;
  }
  div.desc {
    margin-bottom: 0.25rem;
  }
 
  div.desc > span {
    font-size: 0.75rem;
  }

  div.offer-status {
    padding: 0.25rem;
    width:100px;
    text-align: center;
    font-size: 0.75rem;
  }
}

/* offer component */

/* history component */

div.history-page {
  flex: 1 1;
  padding: 2rem 1.5rem;
  padding-right: 3.25rem;
  background: #f1f5fa;
  overflow-y: scroll;
}

div.no-history-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

div.no-history-page > p:nth-child(1) {
  font-size: 1.5rem;
  font-weight: 700;
  color: #1c5d99;
}

div.no-history-page > p:nth-child(2) {
  font-size: 1rem;
  font-weight: 400;
}

div.history-page > p {
  color: #262626;
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 2.5rem;
}

div.history-page-container {
  display: flex;
  flex-direction: row;
  gap: 3.75rem;
  margin-top: 2rem;
}

@media screen and (max-width: 426px) {
  div.history-page-container {
    margin-top: 1rem;
  }
}

div.history-page-container > div {
  flex: 1;
}

div.history-container-1 > div > p {
  color: #5f5f5f;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

div.history-container-1 > div > div > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem;
  margin-bottom: 0.625rem;
  cursor: pointer;
}

div.history-container-1 > div > div > div:hover {
  background-color: #eaf7e2;
}

div.active {
  background-color: #eaf7e2;
}

div.history-container-1 > div:nth-of-type(1) > div > div:nth-of-type(1) {
  background: #eaf7e2;
}

div.history-container-1 > div > div > div > p {
  color: #262626;
  font-size: 16px;
  font-weight: 400;
}

div.history-container-1 > div > div > div > div > p {
  color: var(--primary, #262626);
  font-size: 12px;
  font-weight: 400;
}

svg.declined > circle {
  fill: #f41c1c;
}
svg.pending > circle {
  fill: #1c5d99;
}

svg.accepted > circle {
  fill: #6eb424;
}

div.history-container-1 > div > div > div > div {
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
}
div.history-container-2{
  display: block;
}
div.history-container-2 > p {
  color: #5f5f5f;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

div.history-container-2 > div {
  padding: 1.5rem;
  border-radius: 8px;
  background: #fff;
}

div.history-container-2 > div > div:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 1.5rem;
}

div.history-container-2 > div > div:nth-of-type(1) > p {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

div.history-container-2 > div > div:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

div.history-container-2 > div > div:nth-of-type(2) > div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

div.user-details > p:nth-of-type(1) {
  color: #262626;
  font-size: 0.85rem;
  font-weight: 500;
  flex: 1;
}

div.user-details > p:nth-of-type(2) {
  font-size: 0.85rem;
  font-weight: 400;
  padding: 0.625rem;
  flex: 1;
  text-align: end;
  overflow-wrap: break-word;
}

div.user-details.status > p:nth-of-type(2) {
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

p.accepted {
  background-color: #ddffe0e5;
  color: #108c1c;
  padding: 0.5rem;
  border-radius: 1rem;
}

p.declined {
  color: #f41c1c;
  background: #fab8b8;
  padding: 0.5rem;
  border-radius: 1rem;
}

p.pending {
  background-color: #d2deee;
  color: #1c5d99;
  padding: 0.5rem;
  border-radius: 1rem;
}

@media screen and (max-width: 769px) {
  div.history-page {
    padding: 2rem 1.5rem;
    background-color: #fff;
    padding-bottom: 5.5rem;
  }
  div.history-container-2.big-screen{
    display: none;
  }

  div.history-container-2 > div:nth-of-type(1) {
    padding: 0;
    border-radius: 0;
    background: transparent;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 1rem;
  }
  div.event-cta > p {
    color: #5f5f5f;
    font-size: 1.25rem;
    font-weight: 600;
  }

  div.history-container-2 > div {
    padding: 1rem;
    border-radius: 0.5rem;
    background: #fff;
  }
}

@media screen and (max-width: 425px) {
  div.history-page > p {
    color: #262626;
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 2rem;
  }
  div.history-container-1 > div > p {
    color: #5f5f5f;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.75rem;
  }
  div.history-container-1 > div > div > div > img {
    width: 1.875rem;
    height: 1.875rem;
  }
  div.history-container-1 > div > div > div > p,
  div.history-container-1 > div > div > div > div > p {
    font-size: 0.75rem;
  }

  div.history-container-1 > div > div > div > div > svg {
    height: 0.469rem;
    width: 0.469rem;
  }

  div.history-container-1 > div > div > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }

  div.history-container-2 > div:nth-of-type(1) {
    margin-bottom: 0.75rem;
  }

  div.event-cta > p {
    color: #5f5f5f;
    font-size: 1rem;
    font-weight: 400;
  }
  div.event-cta > svg {
    width: 0.5rem;
    height: 1rem;
  }
  div.history-container-2 > div > div:nth-of-type(1) {
    margin-bottom: 1rem;
  }
  div.history-container-2 > div > div:nth-of-type(1) > img {
    width: 1.875rem;
    height: 1.875rem;
  }
  div.history-container-2 > div > div:nth-of-type(1) > p {
    font-size: 0.75rem;
  }

  div.user-details > p:nth-of-type(1) {
    color: #262626;
    font-size: 0.75rem;
    font-weight: 500;
  }

  div.user-details > p:nth-of-type(2) {
    font-size: 0.75rem;
    padding: 0.25rem;
  }
  div.history-container-2 > div > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}
/* history component */

/* wallet component  */
div.wallet-page {
  flex: 1 1;
  padding: 2rem 1.5rem;
  padding-right: 3.25rem;
  background: #f1f5fa;
  overflow-x: hidden;
  overflow-y: scroll;
}

div.wallet-page-main {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap:1.5rem;
}

div.wallet-page-main > p {
  padding-top: 2rem;
  color: #262626;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.75rem;
}
div.wallet-page-container {
  margin-top: 1rem;
}

div.wallet-page-container > div:nth-of-type(1) {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

div.wallet-page-container > div:nth-of-type(1) > button {
  border: none;
  padding-block: 0.625rem;
  padding-inline:1rem;
  border-radius: 0.5rem;
  color: #fff;
  background: #1c5d99;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  font-size: 16px;
  font-weight: 600;
}

div.wallet-page-container > div:nth-of-type(1) > button:hover{
  opacity: 0.8;
}

div.wallet-page-container > div:nth-of-type(2) {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  overflow-x: scroll;
  padding-bottom: 1rem;
}

div.card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 184px;
  width: 40%;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  color: #fff;
}

div.card > p {
  font-size: 16px;
  font-weight: 400;
}

.card-two {
  background: linear-gradient(104.39deg, #53848e -8.25%, #034655 106.77%);
}
.card-one {
  background: linear-gradient(102.31deg, #1c5d99 2.43%, #2b5881 52.89%, #071c2f 106.62%);
}
.card-three {
  background: linear-gradient(109.51deg, #a70d7e -1.64%, rgba(151, 20, 116, 0.99526) 41.34%, rgba(48, 3, 36, 0.99) 89.04%);
}

div.card > div {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  align-items: center;
}

div.card > div > p {
  font-size: 2.5rem;
  font-weight: 400;
}

.card .two {
  background: linear-gradient(104.39deg, #53848e -8.25%, #034655 106.77%);
}

div.wallet-page-container > p {
  color: #5f5f5f;
  font-size: 1.25rem;
  font-weight: 600;
  padding: 6px 0px 16px 4px;
}

div.wallet-steps {
  padding: 1rem 0.75rem;
  border-radius: 8px;
  border: 1px solid #1c5d99;
  position: relative;
  margin-bottom: 1rem;
  cursor: pointer;
}

div.wallet-steps > svg {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

div.wallet-steps > p {
  color: #1c5d99;
  font-size: 1rem;
  font-weight: 400;
}

div.wallet-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}

div.wallet-links > p {
  color: #5f5f5f;
  font-size: 1rem;
  font-weight: 600;
}

div.wallet-links > a {
  color: #1c5d99;
  font-size: 1rem;
  font-weight: 700;
  text-decoration-line: underline;
}

div.transaction-container {
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

div.transaction {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.transaction > div > p {
  color: #262626;
  font-size: 1rem;
  font-weight: 400;
}

div.transaction > div:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

div.transaction > div:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

div.transaction > div:nth-of-type(2) > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
}

div.transaction > div:nth-of-type(2) > div > span {
  color: #262626;
  font-size: 1rem;
  font-weight: 400;
}

div.transaction > div:nth-of-type(2) > span {
  color: #5f5f5f;
  font-size: 1rem;
  font-weight: 500;
}

/*  wallet modal component */
div.wallet-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.41);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

div.wallet-modal-main {
  max-width: 650px;
  width: 90%;
  border-radius: 10px;
  background: #fff;
  padding-block: 1.25rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

div.wallet-modal-main > div{
  width: 55%;
}

div.wallet-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

div.wallet-container > div:nth-of-type(1) > p {
  color: #262626;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
div.wallet-container > div:nth-of-type(1) > p:nth-of-type(2) {
  color: #5f5f5f;
  font-size: 1.25rem;
  font-weight: 400;
}

div.wallet-container > div:nth-of-type(2) {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
  gap: 1rem;
}

div.wallet-container > div:nth-of-type(2) > div > p {
  border-radius: 6.25rem;
  background: #f1f5fa;
  padding: 0.313rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #1c5d99;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.625rem;
  transform: all ease 0.5s;
  cursor: pointer;
}

div.wallet-container > div:nth-of-type(2) > div.selected > p,
div.wallet-container > div:nth-of-type(2) > div:hover > p {
  background-color: #1c5d99;
  color: #f1f5fa;
}

div.wallet-container > div:nth-of-type(2) > div > p:nth-of-type(2) {
  color: #6eb424;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 6.25rem;
  background: transparent;
  padding: 0;
  margin-bottom: 0rem;
}

div.wallet-container > div:nth-of-type(3) > p:nth-of-type(1) {
  color: #1c5d99;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

div.wallet-container > div:nth-of-type(3) > input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #1c5d99;
  background: #fff;
  padding: 0.75rem 0 0.75rem 0.75rem;
  color: #5f5f5f;
  font-size: 1rem;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.625rem;
  transition: all ease 0.5s;
}

div.wallet-container > div:nth-of-type(3) > input:focus {
  outline: none;
  border: 2px solid #1c5c99
}

div.wallet-container > div:nth-of-type(3) > p:nth-of-type(2) {
  display: inline-flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #e6f7d5;
  color: #6eb424;
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

div.wallet-modal-main > div > button {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  border-radius: 0.625rem;
  background: #1c5d99;
  padding-block: 0.5rem;
  color: #fff;
}

div.wallet-modal-main > div > button > p {
  font-size: 20px;
  font-weight: 400;
}

div.wallet-container2 {
  padding-block: 3.125rem;
  max-width: 28.125rem;
  width: 80%;
}

div.wallet-container2 > p {
  color: #000;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

div.wallet-container2 div.payment-method {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  padding: 1rem 1.5rem;
  position: relative;
  border-radius: 0.625rem;
  border: 0.0625rem solid #1c5d99;
  margin-bottom: 1rem;
}

div.wallet-container2 div.payment-method > svg:nth-of-type(2) {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
}

/*  wallet modal component */

/* Token Transaction */

div.token-transaction-heading {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1.5rem;
}
div.token-transaction-heading > p {
  color: #262626;
  font-size: 1.5rem;
  font-weight: 600;
}

@media screen and (max-width: 769px) {
  div.token-transaction-heading > p {
    font-size: 1.25rem;
  }
}

div.token-transaction-main {
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
  flex-grow: 1;
}

div.token-transaction-main > div {
  flex: 1;
}


p.transaction-title {
  color: #5f5f5f;
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 0.75rem;
}

 div.transaction-main-div{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-block: 0.5rem;
  padding-inline-start: 2.5rem;
}

div.transaction-main-div:hover{
  background-color: #e6f7d5;
}

div.transaction-main-div > div:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
}
div.transaction-main-div > div:nth-of-type(2) {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

div.token-transaction-main > div:nth-of-type(1) > div > div > div:nth-of-type(2) > p {
  color: #262626;
  font-size: 1.25rem;
  font-weight: 400;
}

div.token-transaction-main > div:nth-of-type(2) {
  border-radius: 0.5rem;
  border: 0.0625rem solid #58901d;
  padding: 1.5rem 1.125rem;
  align-self: start;
  min-height: 250px;
}

div.token-transaction-main > div:nth-of-type(2) > div:nth-of-type(1) {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  align-items: center;
}

div.token-transaction-main > div:nth-of-type(2) > div:nth-of-type(1) > div > p.trans-time {
  color: #5f5f5f;
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 2rem;
}

div.token-transaction-main > div:nth-of-type(2) > div:nth-of-type(1) > div > p:nth-of-type(2),
div.token-transaction-main > div:nth-of-type(2) > div:nth-of-type(1) > div > p:nth-of-type(3) {
  color: #262626;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1.625rem;
}
div.token-transaction-main > div:nth-of-type(2) > div:nth-of-type(1) > div > p:nth-of-type(3) {
  margin-bottom: 2rem;
}

div.token-transaction-main > div:nth-of-type(2) > div:nth-of-type(1) > div:nth-of-type(2) {
  align-self: center;
}

div.token-transaction-main > div:nth-of-type(2) > div:nth-of-type(1) > div:nth-of-type(2) > div {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
}

div.token-transaction-main > div:nth-of-type(2) > div:nth-of-type(1) > div:nth-of-type(2) > div > span {
  color: #262626;
  font-size: 1.75rem;
  font-weight: 400;
}

div.token-transaction-main > div:nth-of-type(2) > div:nth-of-type(1) > div:nth-of-type(2) > p {
  padding: 5px;
  border-radius: 6.25rem;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0;
}

div.report-trans {
  display: flex;
  flex-direction: row;
  gap: 0.625rem;
  align-items: center;
}

div.report-trans > p {
  color: #1c5d99;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: normal;
}

/* Token Transaction */

/* how it works */

div.how-it-works-heading {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
  margin-bottom: 2rem;
}

div.how-it-works-heading > svg{
  cursor: pointer;
}

div.how-it-works-heading > p {
  color: #262626;
  font-size: 1.5rem;
  font-weight: 600;
}

@media screen and (max-width: 769px) {
  div.how-it-works-heading > p {
    font-size: 1.25rem;
  }
}

div.how-it-works-main {
  padding-left: 0.75rem;
}

div.how-it-works-main-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

div.procedure > p.title {
  color: #1c5d99;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

div.procedure > div {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

div.procedure > div > p.desc {
  color: #262626;
  font-size: 1.25rem;
  font-weight: 400;
}

p.token-balance{
  font-size: 32px;
}

/* how it works */

div.token-details{
  display: none;
}

@media screen and (max-width: 769px) {
  div.wallet-modal-main > div {
    width: 70%;
}
  div.top-right {
    display: none;
  }
  div.wallet-page {
    flex: 1 1;
    padding: 2rem 1.5rem;
    padding-bottom: 5.5rem;
    background: #f1f5fa;
  }
  div.token-details{
    display: block;
  }

  div.wallet-page-main {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 1.0rem;
}
  div.wallet-page-main > p {
    padding-top: 0rem;
    color: #262626;
  }

  div.token-transaction-main > div:nth-of-type(1) > div > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  div.token-details-heading {
    margin-bottom: 1rem;
  }
  div.token-details-main > div:nth-of-type(1) {
    padding: 0.875rem;
  }
  div.token-details-main > div:nth-of-type(1) > p {
    color: #5f5f5f;
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
  }

  div.details-t {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;
  }

  div.details-t > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    gap: 2.125rem;
  }

  div.details-t > div:nth-of-type(1) > p {
    color: #262626;
    font-size: 1rem;
    font-weight: 600;
  }
  div.card{
    width: 70%
  }
}

div.details-t > div:nth-of-type(2) {
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}

div.details-t > div:nth-of-type(2) > div {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
div.details-t > div:nth-of-type(2) span {
  color: #262626;
  font-size: 1.5rem;
  font-weight: 400;
}

div.details-t > div:nth-of-type(2) p {
  border-radius: 100px;
  padding: 0.5rem 1.5rem;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 426px) {
  div.wallet-page {
    flex: 1 1;
    padding: 1.0rem 1.0rem;
    padding-bottom: 45rem;
    background: #f1f5fa;
}
  div.wallet-page-main{
    gap:0.5rem
  }
  div.wallet-modal-main > div {
    width: 90%;
}
  div.wallet-page-container {
    margin-top: 0.5rem;
  }
  div.wallet-page-container > div:nth-of-type(1) {
    margin-bottom: 0.5rem;
  }
  div.wallet-page-container > div:nth-of-type(1) > button {
    padding: 0.5rem;
    font-size: 0.75rem;
  }
  div.wallet-page-container > div:nth-of-type(1) > button > svg {
    width: 1rem;
    height: 1rem;
  }

  div.wallet-page-main > p {
    margin-bottom: 0.25rem;
  }
  div.wallet-page-container > div:nth-of-type(2) {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    overflow-x: auto;
    padding-bottom: 2rem;
  }

  div.card {
    width: 85%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border-radius: 0.75rem;
  }
  div.card > p {
    font-size: 1rem;
    font-weight: 400;
  }
  div.card > div > p {
    font-size: 2rem;
    font-weight: 400;
  }

  div.wallet-page-container > p {
    font-size: 1rem;
  }
  div.wallet-steps {
    padding: 1rem 0.75rem;
    border-radius: 10px;
    border: 1px solid #1c5d99;
    position: relative;
    margin-bottom: 1.25rem;
  }

  div.wallet-steps > p {
    font-size: 0.75rem;
  }

  div.wallet-links > p {
    color: #5f5f5f;
    font-size: 1rem;
    font-weight: 400;
  }
  div.wallet-links > a {
    font-size: 1rem;
  }

  div.transaction-container {
    padding: 0.5rem 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 0.5rem;
    background-color: #ffffff;
  }

  div.transaction > div:nth-of-type(2) > div > span {
    color: #262626;
    font-size: 1rem;
    font-weight: 400;
  }

  div.transaction > div:nth-of-type(2) > span {
    color: #5f5f5f;
    font-size: 0.75rem;
    font-weight: 400;
  }
  div.wallet-modal-main {
    padding-block: 0.5rem;
  }
  div.wallet-container > div:nth-of-type(2) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
    gap: 0.5rem;
  }
  div.wallet-container > div:nth-of-type(1) > p {
    font-size: 1rem;
  }
  div.wallet-container > div:nth-of-type(1) > p:nth-of-type(2) {
    color: #5f5f5f;
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: 0;
  }

  div.wallet-container > div:nth-of-type(2) > div > p {
    border-radius: 6.25rem;
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
  }

  div.wallet-container > div:nth-of-type(2) > div > p:nth-of-type(2) {
    color: #6eb424;
    font-size: 0.65rem;
  }
  div.wallet-container > div:nth-of-type(3) > p:nth-of-type(1) {
    color: #1c5d99;
    font-size: 0.75rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
  div.wallet-modal-main > div > button > p {
    font-size: 0.875rem;
  }
  div.wallet-container > div:nth-of-type(3) > input {
    width: 100%;
    padding: 0.25rem 0 0.25rem 0.25rem;
  }
  div.wallet-modal-main > div > button > svg {
    width: 1.25rem;
    height: 1.125rem;
  }
  div.wallet-container > div:nth-of-type(3) > p:nth-of-type(2) {
    display: inline-flex;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: #e6f7d5;
    color: #6eb424;
    font-size: 0.65rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
  }

  div.wallet-container2 > p {
    font-size: 1.25rem;
  }
  div.wallet-container2 div.payment-method {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    padding: 0.75rem 1.25rem;
    position: relative;
    border-radius: 0.625rem;
    border: 0.0625rem solid #1c5d99;
    margin-bottom: 1rem;
  }
  div.wallet-container2 div.payment-method > svg:nth-of-type(1) {
    width: 1.313rem;
    height: 1.375rem;
  }
  div.wallet-container2 div.payment-method > p {
    color: #262626;
    font-size: 1rem;
    font-weight: 400;
  }
  div.wallet-container2 div.payment-method > svg:nth-of-type(2) {
    width: 0.5rem;
    height: 1rem;
  }
  div.token-transaction-heading > p {
    color: #262626;
    font-size: 1.5rem;
    font-weight: 600;
  }
  div.token-transaction-heading {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    padding-top: 0rem;
    align-items: center;
    margin-bottom: 1rem;
  }
  div.token-transaction-main > div:nth-of-type(1) {
    display: inline-flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  div.token-transaction-main > div:nth-of-type(1) > div > p {
    color: #5f5f5f;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.5rem;
  }
  div.token-transaction-main > div:nth-of-type(1) > div > div > div:nth-of-type(1) {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    flex:1;
    justify-content: space-between; 
    padding-inline-start: 0;
  }
  div.token-transaction-main > div:nth-of-type(1) > div > div > div:nth-of-type(1) > p {
    color: #262626;
    font-size: 0.875rem;
    font-weight: 400;
  }
  div.token-transaction-main > div:nth-of-type(1) > div > div > div:nth-of-type(2) > p {
    color: #262626;
    font-size: 1rem;
    font-weight: 400;
  }
  div.how-it-works-heading {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  div.how-it-works-heading > p {
    color: #262626;
    font-size: 1.25rem;
    font-weight: 600;
  }
  div.procedure > p.title {
    color: #1c5d99;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  div.procedure > div > p.desc {
    color: #262626;
    font-size: 0.75rem;
    font-weight: 400;
  }

  div.wallet-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}

/* wallet component  */

/* passward component  */

div.password-page {
  flex: 1 1;
  padding: 2rem 1.5rem;
  padding-right: 3.25rem;
  background: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
}

div.password-page-main {
  position: relative;
}

div.password-page-heading {
  padding-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 4rem;
}

div.password-page-heading > p {
  color: #262626;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
}

div.password-container {
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  margin-top: 3rem;
}

@media screen and (max-width: 426px) {
  div.password-container {
    margin-top: 1rem;
  }
}

div.password-container > p {
  color: #262626;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

@media screen and (max-width: 426px) {
  div.password-container > p {
    text-align: center;
    margin-bottom: 1rem;
  }
}

div.passworddd {
  position: relative;
  margin-bottom: 1rem;
  width: 100%;
}

div.passworddd > input {
  height: 100%;
  width: 100%;
  padding: 1rem 0 1rem 1rem;
  border: none;
  background: #f1f5fa;
  border-radius: 4px;
}

@media screen and (max-width: 426px) {
  div.passworddd {
    margin-bottom: 0.5rem;
  }
  div.passworddd > input {
    padding: 0.75rem 0 0.75rem 0.75rem;
    border: none;
    background: #f1f5fa;
    border-radius: 4px;
  }
}

div.passworddd > input:focus {
  outline: none;
  background: transparent;
  border: 1px solid #1c5d99;
}
form.password-form > button {
  border: none;
  width: 100%;
  padding-block: 0.625rem;
  border-radius: 10px;
  background: #1c5d99;
  color: #fff;
}

div.confirm {
  margin-bottom: 2rem;
}

@media screen and (max-width: 426px) {
  div.confirm {
    margin-bottom: 1rem;
  }
}

div.password-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.41);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}

div.password-modal-main {
  background-color: #fff;
  max-width: 40.625rem;
  width: 80%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-block: 4.688rem;
  border-radius: 0.625rem;
}

div.password-modal-main > div {
  max-width: 25rem;
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.688rem;
}

/* password modal*/

@media screen and (max-width: 769px) {
  div.password-page {
    flex: 1 1;
    padding: 2rem 1.5rem;
    background: #fff;
  }
}

@media screen and (max-width: 426px) {
  div.password-page-heading > p {
    color: #262626;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
  }
}

/* password modal*/
/* passward component  */

/* notification component */

div.notification-page {
  flex: 1 1;
  padding: 2rem 1.5rem;
  padding-right: 3.25rem;
  background: #fff;
  overflow-x: hidden;
  overflow-y: scroll;
}

div.notification-page-main {
  position: relative;
}

div.notification-page-heading {
  padding-top: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 3rem;
}

div.notification-page-heading > p {
  color: #262626;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
}

div.notication-container {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-top: 2.5rem;
}

div.noti > p {
  color: #5f5f5f;
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 1.313rem;
}

div.noti > div {
  position: relative;
  padding: 0.25rem 2rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
}

div.noti > div > p:nth-of-type(1) {
  color: #262626;
  font-size: 1.5rem;
  font-weight: 400;
}

div.noti > div > p:nth-of-type(1) {
  color: #5f5f5f;
  font-size: 1.25rem;
  font-weight: 400;
}

svg.symbol {
  position: absolute;
  top: 0;
  left: 0;
}

span.noti-time {
  position: absolute;
  top: 0;
  right: 0;
  color: #6eb424;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: normal;
}

span.noti-time.time2 {
  color: #5f5f5f;
}

@media screen and (max-width: 769px) {
  div.notification-page {
    flex: 1 1;
    padding: 2rem 1.5rem;
    background: #fff;
    padding-bottom: 5.5rem;
  }
}

@media screen and (max-width: 426px) {
  div.notification-page-heading {
    padding-top: 0rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1rem;
  }
  div.notication-container {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 1.25rem;
  }
  div.notification-page-heading > p {
    color: #262626;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
  }

  div.noti > p {
    color: #5f5f5f;
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
  }

  div.noti > div > p:nth-of-type(1) {
    color: #5f5f5f;
    font-size: 20px;
    font-weight: 400;
  }
  div.noti > div > p:nth-of-type(2) {
    font-size: 1rem;
  }

  span.noti-time {
    font-size: 1rem;
  }
}

/* notification component */

/* react calender */

/* react calender */

div.main-calender {
  padding-block: 0.5rem;
  border-radius: 12px;
  background: #fff;
  border: none;
  box-shadow: 8px 3px 24px 12px rgba(19, 62, 102, 0.05);
}

abbr[title] {
  text-decoration: none;
}

.react-calendar__month-view__days .react-calendar__tile--active {
  background: #6eb424 !important;
  color: white !important;
}

.react-calendar__month-view__days .react-calendar__tile {
  border-radius: 25px;
  height: 50px;
}

.react-calendar__month-view__days .react-calendar__tile{
  color: #000 !important;
}

.react-calendar__month-view__days .react-calendar__tile--active {
  background: #6eb424 !important;
  color: white !important;
}


.react-calendar__month-view__weekdays__weekday{
  color: #9b9b9b !important;
}

.react-calendar__month-view__days .react-calendar__tile.inactive-day{
  color: gray !important;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  background: #6eb424 !important;
  color: white !important;
}

.react-calendar__month-view__days .react-calendar__month-view__days__day--neighboringMonth, 
.react-calendar__decade-view__years__year--neighboringDecade, 
.react-calendar__century-view__decades__decade--neighboringCentury {
  color: gray !important;
}

/* react calender */

/* showactive day component*/

div.dashboard-main-content2 {
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
  margin-top: 1rem;
}

div.dashboard-main-content2 > div:nth-of-type(1) {
  margin-top: 2.5rem;
}

div.dashboard-main-content2 > div:nth-of-type(2) {
  flex: 1;
}

div.dashboard-main-content2 > div:nth-of-type(2) > p {
  color: #5f5f5f;
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

div.dashboard-main-content2 > div:nth-of-type(2) > div {
  border: 0.0625rem solid #6eb424;
  padding: 1.5rem 2rem;
  padding-bottom: 3.75rem;
  border-radius: 1rem;
  position: relative;
}

div.dashboard-main-content2 > div:nth-of-type(2) > div.no-event {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 685px;
}

div.dashboard-main-content2 > div:nth-of-type(2) > div.no-event > p {
  font-size: 1.5rem;
  font-weight: 600;
}

div.d-img-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  margin-bottom: 1.5rem;
}

div.dashboard-event-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

div.dashboard-event-details > div:nth-of-type(1),
div.dashboard-event-details > div:nth-of-type(2) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

div.d-event-info > p:nth-of-type(1) {
  color: #262626;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 0.75rem;
}

div.d-event-info > p:nth-of-type(2) {
  color: #5f5f5f;
  font-size: 1.25rem;
  font-weight: 400;
}

div.copy-details {
  position: absolute;
  display: flex;
  flex-direction: row;
  gap: 12px;
  bottom: 2%;
  align-items: center;
  justify-content: center;
}

div.copy-details > svg {
  cursor: pointer;
}

div.copy-details > p {
  display: block;
  color: #1c5d99;
  font-size: 1.25rem;
  font-weight: 600;
}
@media screen and (max-width: 426px) {
  div.copy-details > p {
    display: none;
  }
  div.copy-details {
    top: 0;
    right: 0;
    justify-content: start;
    align-items: start;
  }
  div.copy-details.copied > svg > path {
    fill: #808080;
  }
}

div.d-img-container > p {
  color: #262626;
  font-size: 1.25rem;
  font-weight: 400;
}

@media screen and (max-width: 951px) {
  div.dashboard-main-content2 {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
  }
  .react-calendar {
    width: 400px;
  }
}

@media screen and (max-width: 768px) {
  div.d-event-info > p:nth-of-type(1) {
    color: #262626;
    font-size: 1.25rem;
    font-weight: 600;
  }
  div.d-event-info > p:nth-of-type(2) {
    color: #5f5f5f;
    font-size: 1rem;
    font-weight: 400;
  }

  div.dashboard-main-content2 > div:nth-of-type(1) {
    margin-top: 1rem;
  }
  div.dashboard-main-heading {
    margin-bottom: 1rem;
  }
  div.dashboard-main-heading > div > p.calendar-text {
    font-size: 1.25rem;
  }
  div.dashboard-main-content2 > div:nth-of-type(2) > p {
    color: #5f5f5f;
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 426px) {
  div.dashboard-main-content2 > div:nth-of-type(1) {
    margin-top: 0rem;
  }
  div.main-calender {
    width: 100%;
  }

  div.dashboard-main-content2 > div:nth-of-type(2) > div {
    border: none;
    padding: 0;
    border-radius: 0;
  }

  div.d-img-container {
    justify-content: start;
  }
  div.dashboard-event-details > div:nth-of-type(1),
  div.dashboard-event-details > div:nth-of-type(2) {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 1rem;
  }

  div.d-event-info > p:nth-of-type(1) {
    color: #262626;
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 0.5rem;
  }

  div.d-event-info > p:nth-of-type(2) {
    color: #5f5f5f;
    font-size: 0.875rem;
    font-weight: 400;
  }

  div.dashboard-main-content2 > div:nth-of-type(2) > p {
    font-size: 1rem;
  }

  div.d-img-container > p {
    color: #262626;
    font-size: 1rem;
    font-weight: 400;
  }

  div.dashboard-event-details {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
}
/* showactive day component*/

/* support page */

div.support-page {
  flex: 1 1;
  padding: 2rem 1.5rem;
  padding-right: 3.25rem;
  background: #f1f5fa;
  overflow-x: hidden;
  overflow-y: scroll;
}

div.support-page-main {
  position: relative;
}

div.support-page-main > p {
  padding-top: 2rem;
  color: #262626;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1.25rem;
}

div.support-page-container {
  margin-top: 1.25rem;
  position: relative;
  min-height: 80vh;
}

div.support-page-container > div.support-loader{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  font-size: 2rem;
  color: #1C5D99;
}

div.support-page-container > div > p {
  color: #1c5d99;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1.25rem;
}

div.faq {
  position: relative;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #c3c3c3;
}

div.faq > svg {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.5s;
}

div.faq > svg.open {
  transform: rotate(180deg);
}

p.faq-question {
  color: #262626;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
  cursor: pointer;
}

p.faq-answer {
  color: #262626;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 769px) {
  div.support-page {
    padding: 1.75rem 1.5rem;
    padding-bottom: 6.5rem;
  }
  div.support-page-main > p {
    padding-top: 0rem;
  }
  div.faq > svg {
    width: 1.5rem;
    height: 1.563rem;
  }
}
@media screen and (max-width: 426px) {
  div.support-page-main > p {
    font-size: 1.25rem;
  }
  div.support-page-container > div > p {
    color: #1c5d99;
    font-size: 1.125rem;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 1.25rem;
  }
  p.faq-question {
    width: 95%;
    overflow-wrap: break-word;
    color: #262626;
    font-size: 1rem;
    font-weight: 600;
    margin-bottom: 0.75rem;
  }
  p.faq-answer {
    width: 95%;
    overflow-wrap: break-word;
    color: #262626;
    font-size: 0.875rem;
    margin-bottom: 0.5rem;
  }
}

/* support page*/

/* switch role modal */

div.switch-role-modal {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.41);
  display: flex;
  justify-content: center;
  align-items: center;
}

div.switch-role-modal-main {
  background-color: #fff;
  max-width: 530px;
  width: 80%;
  padding-block: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.625rem;
}

@media screen and (max-width: 768px) {
  div.switch-role-modal-main{
    padding-block: 2rem;
  }
}
div.switch-role-modal-main > p.switch-title {
  color: #262626;
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 0.5rem;
  text-align: center;
}

p.logout-modal-title {
  margin-bottom: 1rem;
}

div.switch-role-modal-main > p.switch-desc {
  color: #5f5f5f;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1.75rem;
  text-align: center;
}

div.switch-button {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 250px;
  width: 80%;
}

div.switch-button > button {
  display: flex;
  padding-block: 11px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  font-size: 1rem;
}

button.btn-vendor {
  background: #1c5d99;
  color: #fff;
  border: none;
}

button.btn-vendor.logout-modal-btn {
  background: #f41c1c;
}

button.btn-planner {
  border: 1px solid #1c5d99;
  color: #1c5d99;
  background-color: transparent;
}

@media screen and (max-width: 426px) {
  div.switch-role-modal-main > p.switch-title {
    font-size: 1rem;
  }

  div.switch-role-modal-main > p.switch-desc {
    color: #5f5f5f;
    font-size: 0.75rem;
    margin-bottom: 1.25rem;
  }
  div.switch-button {
    gap: 0.5rem;
  }
}

/* switch role modal */

/* report event component */

div.report-event-page {
  flex: 1 1;
  padding: 2rem 1.5rem;
  padding-right: 3.25rem;
  background: #fff;
  overflow-y: scroll;
}

div.report-event-main {
  position: relative;
}
div.report-event-main-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  padding-top: 2rem;
  margin-bottom: 4.75rem;
}

div.report-event-main-heading > p {
  color: #262626;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: normal;
}

div.report-event-main-content {
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
}

p.report-form-title {
  color: #262626;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: 3rem;
  margin-bottom: 1.25rem;
  text-align: center;
}

div.report-form-main label {
  display: block;
  color: #262626;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  font-weight: 600;
}
div.report-form-main input {
  padding: 0.875rem 1rem 0.875rem 1rem;
  width: 100%;
  background: #f1f5fa;
  border-radius: 0.25rem;
  font-size: 1rem;
  border: none;
  margin-bottom: 1rem;
}
div.report-form-main select {
  padding: 0.875rem 1rem 0.875rem 1rem;
  width: 97%;
  border-radius: 0.25rem;
  font-size: 1rem;
  border: none;
}

div.report-form-main input::placeholder {
  font-size: 1rem;
}

div.report-form-main input:focus {
  border: 0.0625rem solid #1c5d99;
  background: transparent;
  outline: none;
}

div.text-area > textarea {
  width: 100%;
  padding: 14px 16px;
  border-radius: 0.25rem;
  background: #f1f5fa;
  margin-bottom: 0.5rem;
  border: none;
}
div.text-area > textarea:focus {
  outline: none;
  border: 1px solid #1c5d99;
  background-color: transparent;
}

div.text-area > p {
  text-align: end;
  color: #5f5f5f;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1rem;
}

div.file-container {
  display: block;
  position: relative;
  cursor: pointer;
  margin-bottom: 1rem;
}

div.report-form-main input.file-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  z-index: 2;
  opacity: 0;
}

div.file-container > span {
  display: inline-block;
  padding: 0.75rem 0 0.75rem 0.75rem;
  border-radius: 8px;
  border: 1px dashed #1c5d99;
  width: 100%;
  z-index: 1;
}

button.report-submit {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-block: 0.75rem;
  border-radius: 0.625rem;
  background: #1c5d99;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 400;
  border: none;
}

@media screen and (max-width: 769px) {
  div.report-event-page {
    padding: 1.75rem 1.5rem;
    padding-bottom: 7rem;
  }
  div.report--main-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    padding-top: 0rem;
    margin-bottom: 2.5rem;
  }
}

@media screen and (max-width: 426px) {
  div.report-event-main-heading > svg {
    width: 0.5rem;
    height: 1rem;
  }
  div.report-event-main-heading > p {
    font-size: 1.25rem;
  }
  div.report-event-main-heading {
    margin-bottom: 1.5rem;
  }
  p.report-form-title {
    font-size: 20px;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  div.report-form-main label {
    font-size: 1rem;
    margin-bottom: 0.75rem;
  }

  div.report-form-main input {
    padding: 0.5rem 0 0.5rem 0.75rem;
    margin-bottom: 0.75rem;
  }

  div.text-area > textarea {
    padding: 8px 12px;
  }

  div.text-area > p {
    font-size: 0.75rem;
    margin-bottom: 0.75rem;
  }

  button.report-submit {
    font-size: 1rem;
  }

  div.file-container {
    display: block;
    position: relative;
    cursor: pointer;
    margin-bottom: 0.75rem;
  }
}

/* report event component */

/*Header Component*/
div.partypal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}



div.page-heading > p {
  color: #262626;
  font-size: 1.5rem;
  font-weight: 600;
}

.partypal-header-img-contsiner {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

div.partypal-header-img-contsiner > img{
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  cursor: pointer;
}

@media screen and (max-width: 769px) {
  div.partypal-header-img-contsiner > img {
    display: none;
  }
  div.partypal-header-img-contsiner > svg{
    height: 20px;
    width: 20px;
  }
  div.page-heading > p {
    font-size: 1.25rem;
  }
  div.partypal-header.center {
    justify-content: center;
  }
  
  div.partypal-header.center svg{
    display: none;
  }

}
/*Header Component*/

/*sidebar logo*/


div.desktop-sidebar .full-icon {
  display: block;
  max-width: 65%;
}
div.desktop-sidebar .small-logo {
  display: none;
}
div.desktop-sidebar.closed .full-icon {
  display: none;
}

div.desktop-sidebar.closed .small-logo {
  display: block;
  width: 28px;
  height: 24px;
  margin: 0 auto;
}

div.vendor.closed div.switch-role, div.planner.closed div.switch-role{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 66px;
}

/*sidebar logo*/

.notification-offer-container{
  cursor: pointer;
  margin-bottom: 1rem;
}
.notification-offer-container:last-child{
  margin-bottom: 0;
}
.datepicker{
  width:100% !important;
  display: block;
}
.datepicker.mobile{
  display: none;
}
@media screen and (max-width:768px) {
  .datepicker{
    display: none;
  } 
  .datepicker.mobile{
    display: block;
  }
}

svg.moveleft{
  animation: moveLeft 1s ease-in-out  infinite alternate;
}

@keyframes moveLeft {
  0%{ 
    transform: translateX(0px); 
  }
  100%{
    transform: translateX(4px)
  }
}

div.main-loader{
  animation: loader 3s linear infinite alternate;
}

@keyframes loader {
  0%{
    width: 10px
  }
  100%{
    width: 150px
  }
}

/*offer component*/
  div.offer-component{
    background-color: #ffffff;
    border-radius: 0.5rem;
    padding-block: 2rem;
    padding-inline-start: 1.0rem;
    padding-inline-end: 2.5rem;
    margin-top: 1rem;
  }
  div.offer-component > div > div:nth-of-type(1){
    display: flex;
    gap:0.5rem;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  div.offer-component > div > div:nth-of-type(1) > svg{
    width: 24px;
    height: 24px;
    border-radius: 100%;
  }
  div.offer-component > div > div:nth-of-type(1) > p{
    color:#333333;
    font-size: 1.125rem;
  }
  div.offer-component > div > div:nth-of-type(2), div.offer-component > div > div:nth-of-type(3){
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  div.offer-component > div > div:nth-of-type(2) > div{
    flex: 1
  }
  div.offer-component > div > div:nth-of-type(3) > div:nth-of-type(1){
    flex:2
  }
  div.offer-component > div > div:nth-of-type(3) > div:nth-of-type(2){
    flex: 1
  }

  div.offer-component > div > div:nth-of-type(2) > div > p:nth-of-type(1),
  div.offer-component > div > div:nth-of-type(3) > div > p:nth-of-type(1){
    color: #262626;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  } 
  div.offer-component > div:nth-of-type(2) > div > p:nth-of-type(2),
  div.offer-component > div:nth-of-type(3) > div > p:nth-of-type(2){
    font-size: 14px;
    color: #737373
  }

  div.offer-component > div > div:nth-of-type(4){
    display: inline-flex;
    width:300px;
    flex-direction: column;
    gap:1.0rem;
    margin-bottom: 1.0rem 
  }

  div.offer-component > div > div:nth-of-type(4) > div{
    display: flex;
    flex-direction: row;
    gap: 25px;
    align-items: center;
  }
  div.offer-component > div > div:nth-of-type(4) > div > p,
  div.offer-component > div > div:nth-of-type(4) > div > div{
    flex: 1
  }
  div.offer-component > div > div:nth-of-type(4) > div > p:nth-of-type(1){
    color: #262626;
    font-weight: 600;
    font-size: 1rem;
  }

  div.offer-component > div > div:nth-of-type(4) > div > p:nth-of-type(2){
    text-align: end;
    font-size: 14px;
    color: #262626;
  }
  div.offer-component > div > div:nth-of-type(4) > div:nth-of-type(2) > p:nth-of-type(2){
    display:flex;
    justify-content: center;
  }

  div.offer-component > div > div:nth-of-type(5) > p{
    color: #262626;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
  div.offer-component > div > div:nth-of-type(5) > button{
    padding-block: 0.5rem;
    padding-inline: 1rem;
    border: none;
    border-radius: 0.5rem;
    color: #ffffff;
    background-color: #1C5D99;
  }
  div.offer-component > div > div:nth-of-type(5) > button:hover{
    opacity: 0.85;
  }


@media screen and (max-width: 426px) {
  div.container-offer-component{
    padding-bottom: 7.5rem;
  }
  div.offer-page {
    padding: 1rem 1rem;
}
div.offer-component {
  border-radius: 0.5rem;
  padding-block: 1rem;
  padding-inline: 1.0rem;
}
div.offer-component > div > div:nth-of-type(2), div.offer-component > div > div:nth-of-type(3) {
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}
div.offer-component > div > div:nth-of-type(2) > div > p:nth-of-type(1), div.offer-component > div > div:nth-of-type(3) > div > p:nth-of-type(1) {
  margin-bottom: 0.25rem;
}
div.offer-component > div > div:nth-of-type(4) {
  display: flex;
  width:100%;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1.0rem;
}
div.offer-component > div > div:nth-of-type(4) > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}
}

/*offer component*/