.planner-header {
	flex:1;
	display: flex;
	padding: 1rem 0.5rem;
	align-items: center;
	justify-content: space-between;
	margin: 0px 12px 0px 8px;
}

.planner-header p {
	color: #262626;
	font-size: 1.25rem;
	font-weight: 600;
}

.planner-header .left {
	display: flex;
	align-items: center;
	gap: 1rem;
}

@media screen and (max-width:768px) {
	.planner-header .left {
		gap: 0.5rem;
	}	
}

.planner-header .left span {
	font-size: 1rem;
	color: #5f5f5f;
}

@media screen and (max-width: 768px) {
	.planner-header .right > svg{
		width: 20px;
		height: 20px;
	}	
	.planner-header .right > img {
		display: none;
	}	
}

.notification-bell {
	cursor: pointer;
}

.planner-previous-btn {
	cursor: pointer;
}

.planner-profile-pic {
	width: 2.5rem;
	height: 2.5rem;
	border-radius: 50%;
	cursor: pointer;
}

@media screen and (min-width: 26.25em) {
	.planner-header p {
		font-size: 1.5rem;
	}
}

@media screen and (min-width: 47.9375em) {
	.planner-header {
		padding: 1.0rem 1.5rem;
	}

	.planner-header p {
		font-size: 1.5rem;
	}

	.planner-header .right {
		display: flex;
		align-items: center;
		gap: 1rem;
	}
}
